
























































































































import type { Venda } from '@/models'
import { Fornecedor, Pageable } from '@/models'
import { Vue, Component, Ref, Watch, PropSync } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase, FindFornecedorUseCase } from '@/usecases'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import { listasDeModalidadesDeFrete } from '@/shareds/venda-shareds'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'

@Component({
	components: {
		Confirmacao,
		CampoDecimal,
	},
})
export default class DialogoDeEdicaoDeTransporteDaVenda extends Vue {
	@PropSync('value', { type: Object }) venda!: Venda
	@Ref() campoDeModalidade!: HTMLInputElement
	@Ref() form!: HTMLFormElement

	mostra: boolean | null = null
	buscandoFornecedores: boolean | null = null
	salvando = false

	obrigatorio = obrigatorio
	listasDeModalidadesDeFrete = listasDeModalidadesDeFrete
	listaDeFornecedores: Fornecedor[] = []
	
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()
	findFornecedorUseCase = new FindFornecedorUseCase()

	pageable: Pageable = {
		page: 0,
		size: 10,
	}
	busca: string | null = null
	bucouTodasAsTransportadoras = false
	vendaAlterada: Venda | null = null

	calculaPesoLiquido() {
		const pesoLiquido = Math.abs(this.venda.itens.reduce((acumulador, item) => acumulador + (item.produto.pesoLiquido * item.quantidade), 0))
		return this.venda.informacoesDeTransporte.pesoLiquido = pesoLiquido
	}

	calculaPesoBruto() {
		const pesoBruto = Math.abs(this.venda.itens.reduce((acumulador, item) => acumulador + (item.produto.pesoBruto * item.quantidade), 0))
		return this.venda.informacoesDeTransporte.pesoBruto = pesoBruto
	}

	alterarVenda() {

		this.vendaAlterada = {
			informacoesDeTransporte: {
				modalidadeDeFrete: this.venda.informacoesDeTransporte.modalidadeDeFrete,
				volume: this.venda.informacoesDeTransporte.volume,
				especie: this.venda.informacoesDeTransporte.especie,
				pesoLiquido: this.venda.informacoesDeTransporte.pesoLiquido,
				pesoBruto: this.venda.informacoesDeTransporte.pesoBruto,
				fornecedor: this.venda.informacoesDeTransporte.fornecedor,
				valorFrete: this.venda.informacoesDeTransporte.valorFrete,
			},

		} as Venda

	}

	created() {
		this.calculaPesoBruto()
		this.calculaPesoLiquido()
		this.alterarVenda()
	}

	@Watch('mostra')
	onChangeMostra() {
		
		if(this.venda.informacoesDeTransporte.pesoBruto === null || this.venda.informacoesDeTransporte.pesoLiquido === null ) {
			this.calculaPesoBruto()
			this.calculaPesoLiquido()
		}
		
		this.listaDeFornecedores = []
		this.pageable.page = 0
		this.bucouTodasAsTransportadoras = false
		this.buscarTransportadoras()
		this.alterarVenda()
		this.calculaPesoBruto()
		this.calculaPesoLiquido()
		this.busca = this.venda.informacoesDeTransporte?.fornecedor?.razaoSocialOuNome || null
		window.requestAnimationFrame(() => {
			this.campoDeModalidade.focus()
		})
	}

	@Watch('vendaAlterada.informacoesDeTransporte.modalidadeDeFrete')
	onChangeModalidadeDeFrete() {
		this.listaDeFornecedores = []
		this.pageable.page = 0

		if (this.vendaAlterada && this.vendaAlterada.informacoesDeTransporte && this.vendaAlterada.informacoesDeTransporte.modalidadeDeFrete === 'Sem Frete') {
			this.vendaAlterada.informacoesDeTransporte.fornecedor = null
			this.vendaAlterada.informacoesDeTransporte.especie = null
			this.vendaAlterada.informacoesDeTransporte.volume = null
			this.vendaAlterada.informacoesDeTransporte.valorFrete = null
		}
		this.buscarTransportadoras()
	}

	async buscarTransportadoras() {
		try {
			this.buscandoFornecedores = true
			
			const params = {
				tipoFornecedor: 'Transportadora',
				page: this.pageable.page ? this.pageable.page : 0,
				size: this.pageable.size,
				sort: this.pageable.sort,
				busca: this.busca,
			}

			const fornecedoresPage = await this.findFornecedorUseCase.find(params)

			if (!fornecedoresPage.last) {
				this.pageable.page = !this.pageable.page
					? 1
					: (this.pageable.page += 1)
				this.listaDeFornecedores.push(...fornecedoresPage.content)
				this.bucouTodasAsTransportadoras = false
			} else {
				this.listaDeFornecedores.push(...fornecedoresPage.content)
				this.bucouTodasAsTransportadoras = true
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscandoFornecedores = false
		}
	}

	esconder() {
		this.mostra = false
	}

	async confirmarAlteracao() {
		this.venda.informacoesDeTransporte = this.vendaAlterada ? this.vendaAlterada.informacoesDeTransporte : {
			especie: null,
			fornecedor: null,
			modalidadeDeFrete: "Sem Frete",
			pesoBruto: null,
			pesoLiquido: null,
			valorFrete: null,
			volume: null,
		}
		this.mostra = false
	}
}
