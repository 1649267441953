import { Inject } from 'inversify-props'
import type { ClienteServiceAdapter } from './adapter'
import { AniversariantesListagem, Cliente, Page, TipoDePagamento, TipoDePagamentoBloqueadoCliente } from '@/models'
import { AxiosRequestConfig } from 'axios'

export class FindClienteUseCase {
  
	@Inject('ClienteServiceAdapter')
	private clienteServiceAdapter!: ClienteServiceAdapter

	find = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
		await this.clienteServiceAdapter.find(params, config)

	findClienteComCashback = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
		await this.clienteServiceAdapter.findClienteComCashback(params, config)


	get = async (id: string, params?: any, config?: AxiosRequestConfig): Promise<Cliente> =>
		await this.clienteServiceAdapter.get(id, params, config)

	existeVinculoDeClinteELoja = async (clienteId: string, lojaId: string): Promise<boolean> =>
		await this.clienteServiceAdapter.existeVinculoDeClinteELoja(clienteId, lojaId)
	
	findClientesVinculadosComLoja = async (params?: any, config?: AxiosRequestConfig): Promise<Page<Cliente>> =>
		await this.clienteServiceAdapter.findClientesVinculadosComLoja(params, config)
	
	findAniversariantes = async (params?: any, config?: AxiosRequestConfig): Promise<Page<AniversariantesListagem>> =>
		await this.clienteServiceAdapter.findAniversariantes(params, config)

	findQtdCuponsPorCpf = async (params?: any): Promise<number> =>
		await this.clienteServiceAdapter.findQtdCuponsPorCpf(params)

	listarTiposDePagamentoBloqueadosCliente = async (cliente: any, lojaId: any): Promise<TipoDePagamento[]> =>
		await this.clienteServiceAdapter.listarTiposDePagamentoBloqueadosCliente(cliente, lojaId)

	findTiposDePagamentosDoCliente = async (clienteId: any, params?: any, config?: AxiosRequestConfig): Promise<Page<TipoDePagamentoBloqueadoCliente>> =>
		await this.clienteServiceAdapter.findTiposDePagamentosDoCliente(clienteId, params, config)

	findClienteByCpfOuCnpj = async (cpfOuCnpjDoCliente: string | null): Promise<Cliente> =>
		await this.clienteServiceAdapter.findClienteByCpfOuCnpj(cpfOuCnpjDoCliente)
}
