

















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class TelaDeNovidades extends Vue {
	carregando = true
	iframeSrc = ''

	created() {		
		this.reloadIframe()
	}
	
	reloadIframe() {
		this.carregando = true		
		this.iframeSrc = ''
		setTimeout(() => {
			this.iframeSrc = 'https://almode.gitbook.io/almode-pdv'
		}, 100)
	}

}
