
















































































































































































































































































































































































































import {
	Vue,
	Component,
	PropSync,
	Watch,
	Ref,
	Prop,
} from 'vue-property-decorator'
import { FindVendaUseCase } from '@/usecases'
import { Venda, ItemDaVenda, ResumoDaVenda, Cliente, TipoDeProduto } from '@/models'
import type { Produto } from '@/models'
import IniciarUmaDevolucao from '@/usecases/venda/IniciarUmaDevolucao'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { maiorQueZero, obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores'
import BuscarVendaParaDevolucaoUseCase from '@/usecases/venda/BuscarVendaParaDevolucaoUseCase'
import CampoDeCodigoDaVenda from '@/components/venda/CampoDeCodigoDaVenda.vue'
import { obterDisplayClienteDaVenda, obterTotalDoItem, obterTotalDoItemDevolucao } from '@/shareds/venda-shareds'
import SeletorDeCliente from '@/views/application/clientes/SeletorDeCliente.vue'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import RodapePersonalizado from '../ui/data-tables/RodapePersonalizado.vue'
import Confirmacao from '../ui/Confirmacao.vue'
import DialogoDeDetalhesDaVenda from './DialogoDeDetalhesDaVenda.vue'
import AppLoading from '../ui/AppLoading.vue'
import BuscaDeProdutoDropdown from '../produto/BuscaDeProdutoDropdown.vue'
import CampoDeClienteNoCaixa from '@/views/application/venda/CampoDeClienteNoCaixa.vue'
import DialogoDeIdentificacaoDaTroca from '@/views/application/venda/DialogoDeIdentificacaoDaTroca.vue'
import { converterProdutoParaProdutoSimples } from '@/shareds/produto-shareds'

@Component({
	components: {
		CampoDeCodigoDaVenda,
		SeletorDeCliente,
		RodapePersonalizado,
		Confirmacao,
		DialogoDeDetalhesDaVenda,
		AppLoading,
		BuscaDeProdutoDropdown,
		CampoDeClienteNoCaixa,
		DialogoDeIdentificacaoDaTroca,
	},
})
export default class DialogoDeDevolucaoDeVenda extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNumeroDaVenda!: HTMLInputElement
	@Ref() campoDeNumeroDaNota!: HTMLInputElement
	@Ref() checkboxDeSelecionarTodos!: HTMLInputElement
	@Ref() campoDeProduto!: HTMLInputElement
	@Ref() seletorClientePresente!: Confirmacao
	@Ref() formDoDialogoDeCliente!: HTMLFormElement
	@Ref() dialogoDeIdentificacaoDaTroca!: DialogoDeIdentificacaoDaTroca
	@Prop() parametroIdentificador!: string | null
	
	pedindoCliente: boolean | false = false
	buscandoCliente: boolean | false = false

	cpfOuCnpjDoClienteDoDialogo: null | string = null
	mostraDialogoDeInformarClientePresenteado = false

	obrigatorio = obrigatorio
	
	headers = [
		{
			text: 'Produto',
			value: 'produto.nome',
			class: 'text-no-wrap',
			width: '100%',
		},
		{
			text: 'Código',
			value: 'Eans',
			class: 'text-no-wrap',
			align: 'right',
		},
		{
			text: 'SKU',
			value: 'sku',
			class: 'text-no-wrap',
			align: 'right',
		},
		{ text: 'Quantidade', value: 'quantidade', class: 'text-no-wrap' },
		{
			text: 'Valor (R$)',
			value: 'preco',
			class: 'text-no-wrap',
			align: 'right',
		},
	]

	parametros = criarParametrosDeBusca()

	selecionados: ItemDaVenda[] = []
	etapaDaDevolucao: 'filtros' | 'pesquisas' | 'escolhendo-itens' = 'filtros'
	clienteDoPresente: 'Comprador' | 'Presenteado' | undefined
	findVendaUseCase = new FindVendaUseCase()
	iniciarDevolucao = new IniciarUmaDevolucao()
	venda: Venda | null = null
	vendaOriginal: Venda | null = null
	devolucaoParcial = false

	maiorQueZero = maiorQueZero
	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	obterTotalDoItemDevolucao = obterTotalDoItemDevolucao

	pagina = 1
	buscando = false
	iniciandoDevolucao = false
	busca = ''

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 0,
	}
	vendasResumidas: ResumoDaVenda[] = []
	buscandoVendas = false

	headersVendas = [
		{ text: 'Identificador', value: 'identificador', sortable: false },
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'Cliente', value: 'displayCliente', sortable: false },
		{ text: 'Data', value: 'diplayDataHora', sortable: false },
		{ text: 'Ações', value: 'actions', align: 'center' },
	]

	get isClienteInformado() {
		return this.parametros.cliente && typeof this.parametros.cliente === 'object'
	}

	get isProdutoInformado() {
		return this.parametros.produto && typeof this.parametros.produto === 'object'
	}

	get tiposDeProdutos() {
		return { tipos: ['Padrão', 'Variante'] as TipoDeProduto [] }
	}

	trocarSelecionarTodos() {
		this.selecionados = this.selecionados.length
			? []
			: (this.selecionados = [...this.itensFormatados])
	}

	voltar() {
		if (this.etapaDaDevolucao !== 'filtros') {
			this.etapaDaDevolucao = 'filtros'
			this.focarNoPrimeiroCampo()
		} else {
			this.parametros.identificador = ''
			this.$emit('voltar')
		}
	}

	iniciarTroca() {
		this.$emit('voltar')
	}

	get showAlert() {
		return AlertModule.show
	}

	focarNoPrimeiroCampo() {
		setTimeout(() => {
			if (this.etapaDaDevolucao === 'filtros') {
				if (this.parametros.numeroDaNota || this.parametros.serieFiscal) {
					this.campoDeNumeroDaNota.focus()
				} else {
					this.campoDeNumeroDaVenda.focus()
				}
			} else {
				this.checkboxDeSelecionarTodos.focus()
			}
		})
	}

	async buscarVenda() {
		if ((this.parametros.cliente || this.parametros.produto) && this.parametros.identificador === '') {
			this.paginacao.page = 0
			await this.buscarListagemDeVendasPorPagina()
			if (this.parametros.cliente && this.parametros.produto) {
				if (this.vendasResumidas.length > 0) {
					this.selecionarVendaParaDevolucao(this.vendasResumidas[0])
				} else {
					AlertModule.setInfo('Não foram encontradas vendas com as informações fornecidas')
					if (VendaModule.vendaAtual) {
						VendaModule.vendaAtual.cliente = this.parametros.cliente
						VendaModule.vendaAtual.cpfDoCliente = this.parametros.cliente.cnpjOuCpf
					}

					const item: ItemDaVenda = {
						id: '',
						produto: converterProdutoParaProdutoSimples(this.parametros.produto),
						quantidade: 1,
						desconto: { isPercentual: true, valor: 0.0 },
						preco: this.parametros.produto.preco || 0,
						precoDeCusto: 0,
						idConjunto: null,
						descontoVarejo: 0,
						percentualCashback: 0,
						diasParaEfetivarCashback: 0,
						isBrinde: false,
						idItemNotaOrigem: null,
						chaveNotaOrigem: null,
						diasParaExpirarCashback: 0,
						possuiDescontoPorTabela: false,
						valorDescontoRateadoDoItem: 0,
						itemDeDevolucao: false,
						isTotalmenteTrocado: false,
						motivoCancelamento: null, 
						isCompra: false,
						idItemOrigem: '',
						isDaVendaReaberta: false,
						vendedor: null,
						autenticadoParaLiberarProdutoSemEstoque: this.vendaOriginal?.autenticandoParaLiberarVendaSemEstoque || false,
						vendaOrigemDoItem: null,
						valorFreteRateado: 0,
						isItemReferenteATroca: false,
						isDeOutraLoja: false,
					}

					this.dialogoDeIdentificacaoDaTroca.mostrar()
					this.dialogoDeIdentificacaoDaTroca.incluirItensNaTabela(item)
				}
			} else {
				this.etapaDaDevolucao = 'pesquisas'
			}
		} else {
			try {
				this.buscando = true
				this.venda = null
				this.venda = await BuscarVendaParaDevolucaoUseCase({
					...this.parametros,
					/* TODO card IAS-180 */
					ambiente: this.turnoDeVenda?.pontoDeVenda.serieFiscal.ambiente,
					grupoEconomicoId: VendaModule.lojaDaVenda?.grupoEconomico.id,
				})

				if (this.venda.isDemonstracao || this.venda.isConsignado) {
					AlertModule.setError('Esta venda é uma demonstração/consignação, utilize a opção Retorno Demonstração/Consignação')
					this.etapaDaDevolucao ='filtros'
					this.parametros.identificador = ''
					return
				}

				this.vendaOriginal = await this.findVendaUseCase.get(this.venda.id)
				this.selecionados = []
				if (this.parametros.produto) {
					this.venda.itens.forEach(item => {
						if (item.produto.sku === this.parametros.produto?.sku) this.selecionados.push(item)
					})
				}

				this.etapaDaDevolucao ='escolhendo-itens'
				this.parametros.identificador = ''
			} catch (error: any) {
				AlertModule.setError(error)
				this.etapaDaDevolucao ='pesquisas'
				this.parametros.identificador = ''
				return
			} finally {
				this.buscando = false
			}

			this.venda?.pontoDeVenda?.loja?.configuracaoDaLoja?.devolucaoParcial && !(this.venda.isConsignado && this.venda.isDemonstracao)
				? (this.devolucaoParcial = true)
				: (this.devolucaoParcial = false)
		}
	}

	resetarParametros() {
		this.parametros.identificador = ''
		this.parametros.numeroDaNota = ''
		this.parametros.serieFiscal = ''
		this.parametros.cliente = null
	}

	async buscarListagemDeVendasPorPagina() {
		if (!VendaModule.lojaDaVenda) return
		if (!this.parametros.cliente && !this.parametros.produto) return

		let vendasPaginadas

		try {
			this.buscandoVendas = true
			this.buscando = true
			vendasPaginadas = await this.findVendaUseCase.list({
				lojaId: VendaModule.lojaDaVenda.id || undefined,
				cliente: this.parametros.cliente ? this.parametros.cliente.cnpjOuCpf : undefined,
				identificadorProduto: this.parametros.produto ? this.parametros.produto.sku : undefined,
				isBuscaParaDevolucao: true,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				sort: 'dataHora,desc',
				grupoEconomicoId: VendaModule.lojaDaVenda.grupoEconomico.id,
			})
		} catch(error) {
			AlertModule.setError(error)
			return
		} finally {
			this.buscandoVendas = false
			this.buscando = false
		}

		this.vendasResumidas = vendasPaginadas.content
		this.paginacao.itemsLength = vendasPaginadas.totalElements
	}

	atualizarPagina(page) {
		this.paginacao.page += page
		this.buscarListagemDeVendasPorPagina()
	}

	get vendasFormatadas() {
		return this.vendasResumidas.map(venda => ({
			...venda,
			displayCliente: obterDisplayClienteDaVenda(venda),
			diplayDataHora: this.diplayDataHora(venda),
		}))
	}

	diplayDataHora(venda: ResumoDaVenda) {
		if (!venda.dataHora) return ''
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	get itensFormatados() {
		return (
			this.venda?.itens.map(item => ({
				...item,
				Eans: item.produto.eans.length
					? item.produto.eans.join(', ')
					: item.produto.eans[0],
				sku: item.produto.sku,	
			})) || []
		)
	}

	get permitirTrocaSemIdentificacao() {
		return VendaModule.lojaDaVenda 
			? VendaModule.lojaDaVenda.configuracaoDaLoja.permitirTrocaSemIdentificacao
			: false
	}

	get totalDeItens() {
		if (this.devolucaoParcial) {
			const totalDeItensParaDevolver = this.selecionados.reduce(
				(total, item) => total + item.quantidade,
				0,
			)
			return !Number.isInteger(totalDeItensParaDevolver)
				? totalDeItensParaDevolver.toFixed(3)
				: totalDeItensParaDevolver
		} else if(this.venda && (this.venda.isConsignado || this.venda.isDemonstracao)) {
			const totalDeItensParaDevolver = this.venda.itens.reduce(
				(total, item) => total + item.quantidade,
				0,
			)
			return !Number.isInteger(totalDeItensParaDevolver)
				? totalDeItensParaDevolver.toFixed(3)
				: totalDeItensParaDevolver
		} else {
			return this.venda?.itens.length
		}
	}

	async devolver() {
		if (!this.venda) return
		if (!this.turnoDeVenda) return
		if (!this.validarTodasAsPaginas()) return
		if (!this.form.validate()) return
		if (!this.vendaOriginal) return

		if (this.venda.isPresente && !this.clienteDoPresente) {
			this.seletorClientePresente.mostrar()
			return
		}

		try {
			this.iniciandoDevolucao = true
			if (this.devolucaoParcial) {
				await this.iniciarDevolucao.devolver({
					itensADevolver: this.selecionados,
					vendaOrigem: this.venda,
					vendaOriginal: this.vendaOriginal,
					turnoDeVenda: this.turnoDeVenda,
					cpfOuCnpjDoCliente: this.cpfOuCnpjDoClienteDoDialogo,
				})
			} else {
				await this.iniciarDevolucao.devolver({
					itensADevolver: this.venda?.itens,
					vendaOrigem: this.venda,
					vendaOriginal: this.vendaOriginal,
					turnoDeVenda: this.turnoDeVenda,
					cpfOuCnpjDoCliente: this.cpfOuCnpjDoClienteDoDialogo,
				})
			}
			this.$router
				.push({ name: this.$route.name || '' })
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				.catch(() => {})

			this.parametros.identificador = ''
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.iniciandoDevolucao = false
			this.clienteDoPresente = undefined
			this.cpfOuCnpjDoClienteDoDialogo = null
			this.mostraDialogoDeInformarClientePresenteado = false
		}
		this.etapaDaDevolucao = 'filtros'
		this.resetarParametros()
	}

	async confirmarTipoClientePresente(opcaoSelecionada: 'Comprador' | 'Presenteado') {
		this.clienteDoPresente = opcaoSelecionada
		
		if (this.clienteDoPresente === 'Presenteado') {
			this.mostraDialogoDeInformarClientePresenteado = true
			return
		}
		await this.devolver()
	}

	async confirmarClientePresenteado() {
		if (!this.formDoDialogoDeCliente.validate()) return
		this.$emit('update:pedindoCliente', false)
		this.mostraDialogoDeInformarClientePresenteado = false
		await this.devolver()
	}

	cancelarSelecao() {
		this.clienteDoPresente = undefined
		this.cpfOuCnpjDoClienteDoDialogo = null
		this.mostraDialogoDeInformarClientePresenteado = false
	}

	atualizarCliente(cliente: Cliente) {
		this.cpfOuCnpjDoClienteDoDialogo = formatarCnpjOuCpf(cliente.cnpjOuCpf)
	}

	validarTodasAsPaginas() {
		const itemInvalido = this.selecionados.find(
			item => typeof this.validarItemDeDevolucao(item) === 'string',
		)
		if (!itemInvalido) return true

		const index = this.itensFormatados.findIndex(
			({ id }) => id === itemInvalido.id,
		)
		if (index === -1) return false

		this.pagina = Math.ceil(index / 6)
		setTimeout(() => {
			this.form.validate()
		})

		return false
	}

	validarItemDeDevolucao(item: ItemDaVenda) {
		const itemOriginal = this.venda?.itens.find(({ id }) => id === item.id)
		if (!itemOriginal) return 'Item selecionado não encontrado'
		const regras = [
			maiorQueZero,
			quantidade => this.validarQuantidade(quantidade, itemOriginal.id),
		]
		for (const regra of regras) {
			const validacao = regra(item.quantidade)
			if (validacao !== true) return validacao
		}
		return true
	}

	validarQuantidade(quantidade: number, id: string) {
		const quantideOriginal = this.obterQuantidadeOriginalDoItem(id)
		if (quantidade > quantideOriginal) return 'Quantidade excedida'
		return true
	}

	obterQuantidadeOriginalDoItem(id: string) {
		const item = this.venda?.itens.find(item => item.id === id)
		if (!item) return 0
		return item.quantidade
	}

	estaSelecionado(itemId: string) {
		const item = this.selecionados.find(item => item.id === itemId)
		if (!item) return true
		return false
	}

	@Watch('mostra', { immediate: true })
	onChangeMostra(mostra: boolean) {
		if (!mostra) return

		this.parametros = criarParametrosDeBusca()
		setTimeout(() => {
			this.form.resetValidation()
			this.focarNoPrimeiroCampo()
		})

		if (this.parametroIdentificador !== null) {
			this.parametros.identificador = this.parametroIdentificador
		}
	}

	@Watch('showAlert')
	onChangeShowAlert(show: boolean) {
		if (!show && this.syncMostra) this.focarNoPrimeiroCampo()
	}

	@Watch('etapaDaDevolucao')
	onChangeEscolhendoItens(etapaDaDevolucao: string) {
		if (etapaDaDevolucao !== 'filtros') return
		this.focarNoPrimeiroCampo()
	}

	@Watch('parametros.produto')
	onChangeProduto(produtoAtual: Produto) {
		if (produtoAtual) {
			this.campoDeProduto.blur()
		}
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	selecionarVendaParaDevolucao(vendaSelecionada: ResumoDaVenda | Venda) {
		if (!vendaSelecionada.identificador) return

		this.parametros.identificador = vendaSelecionada.identificador
		this.buscarVenda()
	}

	clienteNaoExiste() {
		AlertModule.setInfo('Cliente não encontrado')
		this.dialogoDeIdentificacaoDaTroca.mostrar()
	}
}

function criarParametrosDeBusca() {
	return {
		identificador: '',
		idLoja: '',
		numeroDaNota: '',
		serieFiscal: '',
		cliente: null as Cliente | null,
		produto: null as Produto | null,
	}
}
