


























































































































































































import { Vue, Component, PropSync } from 'vue-property-decorator'
import type { Pagamento, Venda } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import {
	obterTotalDoItem,
	obterTotalDosItensDaVenda,
	contarItensDaVenda,
	obterTotalDaVenda,
	obterTrocoDaVenda,
	obterDescontoDaVenda,
	obterSubTotalDoItem,
	obterValorAPagar,
	obterItensComTaxaAplicada,
	obterTotalDoItemComTaxas,
	obterTotalDeTaxas,
	obterValorDeFrete,
	obterDescontoDeCupomDaVenda,
	contarItensDaDemonstracao,
	contarItensDaDevolucao,
	contarItensDaVendaDemonstracao,
} from '@/shareds/venda-shareds'
import copy from 'copy-to-clipboard'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component
export default class ResumoDaVenda extends Vue  {
	@PropSync('value') venda!: Venda

	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	obterTotalDosItensDaVenda = obterTotalDosItensDaVenda
	obterTotalDaVenda = obterTotalDaVenda
	contarItensDaVenda = contarItensDaVenda
	obterDescontoDaVenda = obterDescontoDaVenda
	obterSubTotalDoItem = obterSubTotalDoItem
	obterItensComTaxaAplicada = obterItensComTaxaAplicada
	obterTotalDoItemComTaxas = obterTotalDoItemComTaxas
	obterTotalDeTaxas = obterTotalDeTaxas
	obterValorDeFrete = obterValorDeFrete
	obterDescontoDeCupomDaVenda = obterDescontoDeCupomDaVenda
	contarItensDaDemonstracao = contarItensDaDemonstracao
	contarItensDaDevolucao = contarItensDaDevolucao 
	contarItensDaVendaDemonstracao = contarItensDaVendaDemonstracao

	quantidadeProdutosDevolvidos = 0

	get restante() {
		return obterValorAPagar(this.venda)
	}

	get troco() {
		return obterTrocoDaVenda(this.venda)
	}

	get totalDaVendaOuDevolucao() {
		return obterTotalDoItemComTaxas(this.venda) + (this.restante < 0 ? 0 : this.restante) - this.troco
	}

	set vendaDevolucaoDemonstracao(venda: Venda | null) {
		VendaModule.setVendaDevolucaoDemonstracao(venda)
	}

	get vendaDevolucaoDemonstracao() {
		return VendaModule.vendaDevolucaoDemonstracao
	}

	set vendaDevolucaoConsignada(venda: Venda | null) {
		VendaModule.setVendaDevolucaoConsignada(venda)
	}

	get vendaDevolucaoConsignada() {
		return VendaModule.vendaDevolucaoConsignada
	}

	get itensFormatados() {
		const itensParaVenda = this.venda.itens.filter(item => item.isCompra)

		if (this.venda.isVendaReaberta && this.venda.isDemonstracao && itensParaVenda.length > 0) {
			return itensParaVenda;
		}

		return this.venda.itens;
	}

	get numeroTicket() {
		if (!this.venda.numeroTicket) {
			return false
		}
		return true
	}

	exibirValorComTaxa(pagamento: Pagamento) {
		return pagamento.tipoDePagamento.parcelasComTaxas != null 
							&& pagamento.tipoDePagamento.parcelasComTaxas.length != 0 
							&& !pagamento.qtdeDeParcelas
	}

	copiar(valor: any) {
		copy(valor)

		AlertModule.setInfo('Link de pagamento copiado para a área de transferência')
	}

	onChangeCarregamentoCompleto(carregamentoCompleto) {
		if (carregamentoCompleto) {
			this.quantidadeProdutosDevolvidos = contarItensDaDevolucao(this.venda, this.vendaDevolucaoConsignada, this.vendaDevolucaoDemonstracao)
		}
	}
}

