








































































import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator'
import { FormSerieFiscal } from '@/models/SerieFiscal'
import { obrigatorio, maxDigit } from '@/shareds/regras-de-form'
import { FindLojaUseCase } from '@/usecases'
import { Loja } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class DialogoFormSerieFiscal extends Vue {
	@Ref() form!: HTMLFormElement
	@PropSync('value') mostra!: boolean
	@Ref() campoDeAmbiente!: HTMLInputElement
	@Prop({ type: Function, default: criarSerieFiscal }) initialValue!: () => FormSerieFiscal
	@Prop({ type: Boolean, default: false }) disabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly!: boolean
	@Prop({
		type: Object,
		default: () => ({
			series: [],
		}),
	}) rules!: {
		[key in keyof FormSerieFiscal]?: ((serieFiscal: FormSerieFiscal) => true | string)[]
	}
	@Prop() loja!: Loja | null
	serieFiscal: FormSerieFiscal = criarSerieFiscal()
	obrigatorio = obrigatorio
	maxDigit = maxDigit
	isEdicao = false
	findLojaUseCase = new FindLojaUseCase()

	get rulesComputadas(): { [key in keyof FormSerieFiscal]?: ((value: any) => true | string)[] } {
		return this.rules
			? Object
				.entries(this.rules)
				.reduce((rulesPorKey,  [key, rules]) =>  ({
					...rulesPorKey,
					[key]: rules?.map(rule => () => rule(this.serieFiscal)) || [],
				}), {})
			: {}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.serieFiscal = JSON.parse(JSON.stringify(this.initialValue()))
		this.serieFiscal.id ? this.isEdicao = true : this.isEdicao = false

		setTimeout(() => {
			this.form.resetValidation()
			setTimeout(() => {
				this.campoDeAmbiente.focus()
			})
		})
	}

	async confirmar() {
		if (!this.form.validate()) return
		if (!this.loja) return

		if (this.serieFiscal.tipo === 'Não Eletrônica') {
			const seriesNaoEletronica = await this.findLojaUseCase.buscarSeriesNaoEletronicas(this.loja.id)

			console.log('loja', this.loja)

			if (seriesNaoEletronica.length > 0 || this.loja.seriesFiscais.filter(serie => serie.tipo === 'Não Eletrônica').length > 0) {
				AlertModule.setError('Já existe uma série fiscal não eletrônica')
				return
			}
		}

		this.$emit('confirma', this.serieFiscal)
		this.$emit('input', false)
	}

	@Watch('serieFiscal.tipo')
	onChangeTipoDaSerie() {
		if (this.serieFiscal.tipo === 'Não Eletrônica') {
			this.serieFiscal.naoEletronica = true
		} else {
			this.serieFiscal.naoEletronica = false
		}
	}
}

function criarSerieFiscal(): FormSerieFiscal {
	return {
		id: '',
		serie: null,
		numeroNota: 1,
		tipo: 'Venda',
		ambiente: 'Homologação',
		naoEletronica: false,
	}
}

