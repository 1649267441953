import { injectable } from 'inversify-props'
import { VendaServiceAdapter, VendedorEdit } from '@/usecases/venda'
import { ItemDaVenda, UsuarioBase, Venda, VendaParcial } from '@/models'
import {
	create,
	get,
	find,
	remove,
	update,
	patchCpfNaNota,
	cancelarNotasFiscais,
	cancelarVenda,
	getVendaComReferencias, list,
	patchVendedor,
	removerVendedor,
	gerarIdentificadorDeVenda,
	getVendaComPedido,
	enviarEmail,
	validarIdentificadorDeVenda,
	patchRepresentante,
	devolucaoVendaConsignada,
	devolucaoVendaDemonstracao,
	gerarIdentificadorDeVendaSemTurno,
	listSemTurno,
	findNumeroDePedidos,
	cancelarItem,
	listNota,
	patchVendaOrigem,
	removerVendaOrigem,
	atualizarModalidade,
	verificarSeVendaPossuiNota,
	createVendas,
	atualizarVendedores,
	podeVender,
	atualizarNumeroDoTicket,
	removerNumeroTicket,
	buscarOrigem,
	findVendaByNota,
	findDescontosProgressivosDaLoja,
	findEstacoesConfiguradasPorLoja,
} from '@/api/venda'

@injectable()
export class VendaService implements VendaServiceAdapter {
	gerarIdentificadorDeVenda = async (idPdv: string) => await gerarIdentificadorDeVenda(idPdv)
	get = async (id: string) => await get(id)
	getVendaComPedido = async (id: string) => await getVendaComPedido(id)
	getVendaComReferencias = async (id: string) => await getVendaComReferencias(id)
	find = async (params?: any) => await find(params)
	findNumeroDePedidos = async (params?: any) => await findNumeroDePedidos(params)
	listVendas = async (params?: any) => await list(params)
	listVendasSemTurno = async (params?: any) => await listSemTurno(params)
	create = async (venda: Venda, isSincrono: boolean, vaiGerarNotaEletronica: boolean) => await create(venda, isSincrono, vaiGerarNotaEletronica)
	createVendas = async (venda: Venda[]) => await createVendas(venda)
	remove = async (id: string) => await remove(id)
	update = async (id: string, venda: VendaParcial) => await update(id, venda)
	cancelar = async (id: string, params?: any) => await cancelarVenda(id, params)
	cancelarItem = async (vendaId: string, params?: any) => await cancelarItem(vendaId, params)
	patchCpfNaNota = async (venda: Partial<Venda>) => await patchCpfNaNota(venda)
	cancelarNotasFiscais = async (id: string, motivoDoCancelamentoFiscal: string) => await cancelarNotasFiscais(id, motivoDoCancelamentoFiscal)
	patchVendedor = async (idVenda: string, vendedor: UsuarioBase) => await patchVendedor(idVenda, vendedor)
	removerVendedor = async (idVenda: string, vendedor: UsuarioBase) => await removerVendedor(idVenda, vendedor)
	patchRepresentante = async (idVenda: string, representante: UsuarioBase) => await patchRepresentante(idVenda, representante)
	patchVendaOrigem = async (idVenda: string, vendaOrigemId: string) => await patchVendaOrigem(idVenda, vendaOrigemId)
	removerVendaOrigem = async (idVenda: string) => await removerVendaOrigem(idVenda)
	enviarEmail = async (idVenda: string, email: string) => await enviarEmail(idVenda, email)
	validarIdentificadorDeVenda = async (idPdv: string, identificador: string) => await validarIdentificadorDeVenda(idPdv, identificador)
	devolucaoVendaConsignada = async (venda: Venda) => await devolucaoVendaConsignada(venda)
	devolucaoVendaDemonstracao = async (venda: Venda) => await devolucaoVendaDemonstracao(venda)
	gerarIdentificadorDeVendaSemTurno = async (idLoja: string) => await gerarIdentificadorDeVendaSemTurno(idLoja)
	listNota = async (params?: any) => await listNota(params)
	atualizarModalidade = async (modalidadeId: string, vendaId: string) => await atualizarModalidade(modalidadeId, vendaId)
	verificarSeVendaPossuiNota = async (vendaId: string) => await verificarSeVendaPossuiNota(vendaId)
	atualizarVendedores = async (vendedorEdit: VendedorEdit) => await atualizarVendedores(vendedorEdit)
	podeVender = async (cnpjOuCpf: string, turnoDeVendaId: string) => await podeVender(cnpjOuCpf, turnoDeVendaId)
	atualizarNumeroDoTicket = async (numeroTicket: string | null, vendaId: string) => await atualizarNumeroDoTicket(numeroTicket, vendaId)
	removerNumeroTicket = async (vendaId: string) => await removerNumeroTicket(vendaId)
	buscarOrigem = async (produtoId: string, quantidadeLida: number, lojaId: string, cpfOuCnpjCliente: string, itens: ItemDaVenda[]) => await buscarOrigem(produtoId, quantidadeLida, lojaId, cpfOuCnpjCliente, itens)
	findVendaByNota = async (chaveNF: string) => await findVendaByNota(chaveNF)
	findDescontosProgressivosDaLoja = async (params: any) => await findDescontosProgressivosDaLoja(params)
	findEstacoesConfiguradasPorLoja = async (lojaId: any) => await findEstacoesConfiguradasPorLoja(lojaId)
}


