import { 
	findNotasEmTransito, 
	receberNotaEmTransito, 
	remover, findByChaveDanfe, 
	getProdutosDaNotaEmTransito, 
	salvarProdutoBipadoDaNota, 
	resetarBipagem,
	salvarMotivoProdutoFaltante, 
} from "@/api/notasemtransito"
import { ItemDaNotaEmTransitoSalvo, NotaEmTransito } from "@/models"
import { NotasEmTransitoServiceAdapter } from "@/usecases/fiscal/adapter/NotasEmTransitoServiceAdapter"
import { AxiosRequestConfig } from "axios"
import { injectable } from "inversify"

@injectable()
export class NotasEmTransitoService implements NotasEmTransitoServiceAdapter {
	find = async (params?: any, config?: AxiosRequestConfig) => await findNotasEmTransito(params, config)
	receberNotaEmTransito = async (nota: NotaEmTransito) => await receberNotaEmTransito(nota)
	remover = async (params: any) => await remover(params)
	findByChaveDanfe = async (chaveDanfe: string) => await findByChaveDanfe(chaveDanfe)
	getProdutosDaNotaEmTransito = async (chaveDanfe: string) => await getProdutosDaNotaEmTransito(chaveDanfe)
	salvarProdutoBipadoDaNota = async (produto: ItemDaNotaEmTransitoSalvo) => await salvarProdutoBipadoDaNota(produto)	
	resetarBipagem = async (notaId: string | null) => await resetarBipagem(notaId)
	salvarMotivoProdutoFaltante = async (produto: any) => await salvarMotivoProdutoFaltante(produto)
}