



























import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import BuscaDeProduto from '@/components/produto/BuscaDeProduto.vue'
import { Produto, ItemDaVenda, ProdutoFilho } from '../../models'
import {
	converterProdutoParaProdutoSimples,
	obterQuantidadePesadaNaBalanca,
} from '@/shareds/produto-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindConfiguracaoDaContaUseCase, FindProdutoUseCase } from '@/usecases'
import { ConfiguracaoDaConta } from '@/models/ConfiguracaoDaConta'
import DialogoDeAutenticacao from '@/views/application/venda/DialogoDeAutenticacao.vue'
import { getRequestError } from '@/helpers/axios-helpers'

@Component({
	components: {
		BuscaDeProduto,
		DialogoDeAutenticacao,
	},
})
export default class CampoDeItemDeVenda extends Vue {
	@Ref() campo!: BuscaDeProduto
	@Ref() dialogoDeAutenticacao!: DialogoDeAutenticacao
	@Prop({ type: Boolean, default: true }) validarProduto!: boolean
	@Prop({ type: Boolean, default: false }) isTelaDeTroca!: boolean
	@Prop({ type: String, default: 'Venda' }) tipoMovimentacao!: string

	busca: string | null = null
	produto: Produto | null = null
	erro: null | Error | unknown = null
	configuracaoDeConta: ConfiguracaoDaConta | null = null

	quantidade = 1

	VendaModule = VendaModule
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()
	findProdutoUseCase = new FindProdutoUseCase()

	async created() {
		try {
			this.configuracaoDeConta =
				await this.findConfiguracaoDaContaUseCase.find()
		} catch (error: any) {
			this.erro = error
		}
	}

	async enviarItemDeVenda(produto: ProdutoFilho | null) {
		if (!produto) return

		const item: ItemDaVenda = this.criarItemDaVenda(produto, 0)

		try {
			this.$emit('input', item)
			this.resetarQuantidadeEProduto()
			UserLoginStore.setAutenticandoParaLiberarVendaSemEstoque(true)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async enviarItemDeVendaValidado(produto: ProdutoFilho | null) {
		if (!produto) return
		const quantidadeDeItens =
				produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida !== 'MT'
					? this.quantidade
					: obterQuantidadePesadaNaBalanca(
						this.busca || '',
						this.configuracaoDeConta?.medida || 6,
						this.configuracaoDeConta?.eans || 7,
						this.configuracaoDeConta?.casasNumericas || 4,
					)
		
		const item: ItemDaVenda = this.criarItemDaVenda(produto, quantidadeDeItens)

		try {
			await this.validarEstoque(produto)

			this.$emit('input', item)
			this.resetarQuantidadeEProduto()

			const podeLiberarEstoque = UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque')
			if(podeLiberarEstoque){
				item.autenticadoParaLiberarProdutoSemEstoque = true
			}
			
		} catch (error: any) {
			if (getRequestError(error) == 'A quantidade do produto excede o estoque disponível' ||
				getRequestError(error) == `Produtos ${produto.sku} sem quantidade disponível em estoque` ||
				getRequestError(error) == 'Produto não possui estoque parametrizado') {
				AlertModule.setError(error)
				
				const autenticar = await VendaModule.autenticarAcaoTelaDeCaixa({ regra: 'pode-liberar-produto-sem-estoque' })
				this.resetarQuantidadeEProduto()
				if (autenticar) {
					item.autenticadoParaLiberarProdutoSemEstoque = true
					this.$emit('input', item)
				} 
			} else if(getRequestError(error) !== 'A quantidade do produto excede o estoque disponível' ||
					getRequestError(error) !== `Produtos ${produto.sku} sem quantidade disponível em estoque`) {
				AlertModule.setError(error)
				this.resetarQuantidadeEProduto()
			} else {
				AlertModule.setError(error)
			}
		}
	}

	validarEstoque(produto: ProdutoFilho | null) {
		if (!produto) return
		const quantidadeDoItem = (VendaModule.vendaAtual?.itens
			.find(item => item.produto.id === produto.id)?.quantidade || 0) +
			this.quantidade
		const podeGerarEstoque = this.$route.fullPath === '/caixa' ?
			VendaModule.lojaDaVenda?.configuracaoDaLoja.podeGerarEstoqueNaVenda :
			VendaModule.lojaDaEntrada?.configuracaoDaLoja.podeGerarEstoqueNaVenda

		const eUmaDemonstracaoEReaberta = VendaModule.vendaAtual?.isVendaReaberta
			&& (VendaModule.vendaAtual.isDemonstracao || VendaModule.vendaAtual?.isConsignado)
			&& VendaModule.vendaAtual.itens.some(item => item.produto.id === produto.id)

		if (!podeGerarEstoque) {
			if (!this.podeLiberarProdutoSemEstoque && !eUmaDemonstracaoEReaberta && !this.isTelaDeTroca) {
				if (VendaModule.lojaDaVenda) {
					const eUmaDemonstracaoEReaberta = VendaModule.vendaAtual?.isVendaReaberta 
				&& (VendaModule.vendaAtual.isDemonstracao || VendaModule.vendaAtual?.isConsignado) 
				&& VendaModule.vendaAtual.itens.some(item => item.produto.id === produto.id) 

					if((!podeGerarEstoque && this.tipoMovimentacao !== 'Entrada' && this.tipoMovimentacao !== 'Balanco') && !this.$route.path.includes('/estoques')) {	
						if(!this.podeLiberarProdutoSemEstoque && !eUmaDemonstracaoEReaberta && !this.isTelaDeTroca) {
							if(VendaModule.lojaDaVenda) {
								return this.findProdutoUseCase.validarProduto(produto.sku || '', VendaModule.lojaDaVenda.id, quantidadeDoItem)
							} else if (VendaModule.lojaDaEntrada) {
								return this.findProdutoUseCase.validarProduto(produto.sku || '', VendaModule.lojaDaEntrada.id, quantidadeDoItem)
							}
						}
					}}
			}}
	}

	criarItemDaVenda(produto: ProdutoFilho, quantidadeDeItens: number): ItemDaVenda {
		return {
			id: '',
			produto: converterProdutoParaProdutoSimples(produto),
			quantidade: quantidadeDeItens,
			desconto: { isPercentual: true, valor: 0.0 },
			preco: produto.preco || 0,
			precoDeCusto: 0,
			idConjunto: null,
			descontoVarejo: 0,
			percentualCashback: 0,
			diasParaEfetivarCashback: 0,
			isBrinde: false,
			idItemNotaOrigem: null,
			chaveNotaOrigem: null,
			diasParaExpirarCashback: 0,
			possuiDescontoPorTabela: false,
			valorDescontoRateadoDoItem: 0,
			itemDeDevolucao: false,
			isTotalmenteTrocado: false,
			motivoCancelamento: null,
			isCompra: false,
			idItemOrigem: '',
			isDaVendaReaberta: false,
			vendedor: null,
			autenticadoParaLiberarProdutoSemEstoque: false,
			vendaOrigemDoItem: null,
			valorFreteRateado: 0,
			isItemReferenteATroca: false,
			isDeOutraLoja: false,
		}
	}

	resetarQuantidadeEProduto() {
		this.quantidade = 1;
		setTimeout(() => {
			this.produto = null;
		});
	}

	focus() {
		this.campo.focus()
	}

	blur() {
		this.campo.blur()
	}

	@Watch('busca')
	onChangeBusca(busca: string) {
		if (!this.podeAlterarQuantidadeProduto) return
		if (!busca || !busca.match(/\*|x/g)) return
		const quantidade = Number(busca.split(/\*|x/)[0])
		this.quantidade =
			Number.isNaN(quantidade) || quantidade <= 0 ? 1 : quantidade
		setTimeout(() => {
			this.busca = null
		})
	}

	get podeAlterarQuantidadeProduto() {
		return UserLoginStore.permiteRegraDeNegocio(
			'informar-quantidade-de-produtos',
		)
	}

	get podeLiberarProdutoSemEstoque() {
		return UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque')
	}
}
