































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import {
	SaveLojaUseCase,
	FindLojaUseCase,
	DeleteLojaUseCase,
	ComandaUseCase,
} from '@/usecases'
import {
	Loja,
	Usuario,
	FormLoja,
	FormPontoDeVenda,
	Marca,
	Cliente,
	ComandaForm,
	Comanda,
	ConfiguracaoDeNotificacaoPorUsuario,
	ConfiguracaoDeNotificacaoPorEmail,
} from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import WrapperDeUpload from '@/components/ui/WrapperDeUpload.vue'
import CampoDeCnpj from '@/components/ui/CampoDeCnpj.vue'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import CamposDeEndereco from '@/components/ui/CamposDeEndereco.vue'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import DialogoDePontoDeVenda from '@/components/loja/DialogoDePontoDeVenda.vue'
import CardDeAcoesDoPdv from '@/components/loja/CardDeAcoesDoPdv.vue'
import ConfirmExclusionDialog from '@/components/ui/ConfirmExclusionDialog.vue'
import SeletorDeCfop from '@/components/fiscal/SeletorDeCfop.vue'
import SeletorDeRegimeFiscal from '@/components/fiscal/SeletorDeRegimeFiscal.vue'
import AvatarDeUsuario from '@/components/usuario/AvatarDeUsuario.vue'
import DialogoDeBuscaDeUsuario from '@/components/usuario/DialogoDeBuscaDeUsuario.vue'
import TableDeTiposDePagamento from '@/components/loja/TableDeTiposDePagamento.vue'
import TableDeSeriesFiscais from '@/components/loja/TableDeSeriesFiscais.vue'
import { maiorOuIgualAZero, obrigatorio, maiorOuIgualAUm } from '@/shareds/regras-de-form'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import RevealPasswordField from '@/components/ui/RevealPasswordField.vue'
import TabDeTabelaDePreco from './TabDeTabelaDePreco.vue'
import {
	criarLoja,
	tipoDePagamentoTemRecorrencia,
	usuarioParaOperador,
} from '@/shareds/loja-shareds'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import DialogoDeImportacaoDeOutrasLojas, {
	CamposDaImportacao,
} from './DialogoDeImportacaoDeOutrasLojas.vue'
import {
	formatarCnpjOuCpf,
	removerCaracteresEspeciaisDeTexto,
} from '@/shareds/formatadores'
import { nextTick } from '@/shareds/utils'
import SeletorDeGruposEconomicos from '@/components/loja/SeletorDeGruposEconomicos.vue'
import ListagemDeMarcasPorLoja from './ListagemDeMarcasPorLoja.vue'
import ListagemDeLojasPorLoja from './ListagemDeLojasPorLoja.vue'
import { DataOptions } from 'vuetify'
import ListagemDeClientesPorLoja from '@/components/cliente/ListagemDeClientesPorLoja.vue'
import DialogoDeCadastroDeComandas from '../DialogoDeCadastroDeComandas.vue'
import CardDeAcoesDaComanda from '@/components/loja/CardDeAcoesDaComanda.vue'
import ListaDeNotificacoesPorConfigPorEmail from '@/components/usuario/ListaDeNotificacoesPorConfigPorEmail.vue'
import ListaDeNotificacoesPorConfigPorUsuario from '@/components/usuario/ListaDeNotificacoesPorConfigPorUsuario.vue'
import SeletorDeTabelaDePreco from '@/components/tabeladepreco/SeletorDeTabelaDePreco.vue'
@Component({
	components: {
		WrapperDeUpload,
		CampoDeCnpj,
		CampoDeTelefone,
		CamposDeEndereco,
		EdicaoGenerica,
		DialogoDePontoDeVenda,
		CardDeAcoesDoPdv,
		ConfirmExclusionDialog,
		SeletorDeCfop,
		SeletorDeRegimeFiscal,
		AvatarDeUsuario,
		TableDeTiposDePagamento,
		DialogoDeBuscaDeUsuario,
		TableDeSeriesFiscais,
		RevealPasswordField,
		TabDeTabelaDePreco,
		DialogoDeImportacaoDeOutrasLojas,
		SeletorDeGruposEconomicos,
		ListagemDeMarcasPorLoja,
		ListagemDeClientesPorLoja,
		DialogoDeCadastroDeComandas,
		CardDeAcoesDaComanda,
		ListagemDeLojasPorLoja,
		ListaDeNotificacoesPorConfigPorUsuario,
		ListaDeNotificacoesPorConfigPorEmail,
		SeletorDeTabelaDePreco,
	},
})
export default class EdicaoDeLoja extends Vue {
	@Ref() edicaoGenerica!: EdicaoGenerica<Loja>
	@Ref() form0!: HTMLFormElement
	@Ref() form1!: HTMLFormElement
	@Ref() form2!: HTMLFormElement
	@Ref() form3!: HTMLFormElement
	@Ref() form4!: HTMLFormElement
	@Ref() form5!: HTMLFormElement
	@Ref() form6!: HTMLFormElement
	@Ref() form7!: HTMLFormElement
	@Ref() camposDeTelefones!: CampoDeTelefone[]
	@Ref() dialogo!: DialogoDeImportacaoDeOutrasLojas
	@Prop({ type: Boolean }) novo!: boolean
	@Prop({ type: String, default: '' }) id!: string

	AlertModule = AlertModule
	removerCaracteresEspeciaisDeTexto = removerCaracteresEspeciaisDeTexto
	maiorOuIgualAZero = maiorOuIgualAZero
	maiorOuIgualAUm = maiorOuIgualAUm

	clientes: Cliente[] = []
	loja: FormLoja | null = null
	versaoAnterior: FormLoja | null = null
	carregando = false
	salvando = false
	excluindo = false
	trabalharComRomaneio = false
	appStore = AppStore
	totalDeRegistros = -1
	configuracoesDeNotificacaoPorUsuario: ConfiguracaoDeNotificacaoPorUsuario[] = []
	configuracoesDeNotificacaoPorEmail: ConfiguracaoDeNotificacaoPorEmail[] = []

	headers = [
		{ text: 'Nome', value: 'razaoSocialOuNome' },
		{ text: 'Cpf/Cnpj', value: 'cnpjOuCpf' },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	tipoDeCodigo = ['Sku', 'Ean']

	buscaPorNomeEIdenticador = ''
	comandasSelecionadas: ComandaForm[] | null = null
	tabs = [
		'Geral',
		'Endereço e Contato',
		'Fiscal',
		'Pagamentos',
		'Tabelas de Preços',
		'Marcas',
		'Clientes',
		'Lojas',
		'Notificações',
		'Romaneios',
		'Outros',
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	pdvSelecionado = ''
	aba = null
	abaEmail = null

	get clientesLoja() {
		return this.loja?.clientes
	}

	selecionarModuloDaAba(chave: string) {
		switch(chave) {
			case 'aba-1': 
				return 'Estoque'
			case 'aba-2': 
				return 'Vendas'
			case 'aba-3': 
				return 'Nota em Transito'
			default:
				return 'Estoque'
		}
	}

	@Watch('loja')
	changeTabs() {
		const configTrabalharComRomaneio: boolean =
			this.loja?.configuracaoDaLoja?.trabalharComRomaneio ?? false

		const tabsWithRomaneio = [
			'Geral',
			'Endereço e Contato',
			'Fiscal',
			'Pagamentos',
			'Tabelas de Preços',
			'Marcas',
			'Clientes',
			'Lojas',
			'Notificações',
			'Romaneios',
			'Outros',
		]

		const tabsWithoutRomaneio = [
			'Geral',
			'Endereço e Contato',
			'Fiscal',
			'Pagamentos',
			'Tabelas de Preços',
			'Marcas',
			'Clientes',
			'Lojas',
			'Notificações',
			'Outros',
		]

		this.trabalharComRomaneio = configTrabalharComRomaneio
		this.tabs = configTrabalharComRomaneio
			? tabsWithRomaneio
			: tabsWithoutRomaneio
	}

	get pontosDeVendaDisponiveis(): FormPontoDeVenda[] {
		return this.loja?.pdvs ? this.loja.pdvs : []
	}

	get tab() {
		if (!this.$route.query.t) return 0
		const index = this.tabs.findIndex(tab => tab === this.$route.query.t)
		return index !== -1 ? index : null
	}

	set tab(value: number | null) {
		const tab = typeof value === 'number' ? this.tabs[value] : undefined
		this.$router
			.push({
				name: this.$route.name as string,
				query: { t: !value ? undefined : tab },
			})
			.catch()
	}
	erro: null | Error = null

	saveUseCase = new SaveLojaUseCase()
	findUseCase = new FindLojaUseCase()
	deleteUseCase = new DeleteLojaUseCase()
	comandaUseCase = new ComandaUseCase()

	$refs!: {
		[key: string]: HTMLFormElement
	}

	obrigatorio = obrigatorio

	inserirComanda(comanda: Comanda) {
		if (!this.loja) return
		if (this.loja.comandas === null) this.loja.comandas = []

		this.loja.comandas.push(comanda)
	}

	@Watch('id', { immediate: true })
	onChangeId() {
		this.carregar()
	}

	get razaoSocialSemCaracteresEspeciais() {
		return this.loja
			? removerCaracteresEspeciaisDeTexto(this.loja.razaoSocial)
			: ''
	}

	get nomeDoArquivoCertNfe() {
		if (!this.loja || !this.loja.cnpj) return 'Loja deve ter um cnpj'
		if (!this.loja.urlCertificadoNfe) return 'Nenhum certificado selecionado...'

		return decodeURI(this.loja.urlCertificadoNfe.split('/').pop() as string)
	}

	get pdvsOrdenados() {
		return this.loja
			? [...this.loja.pdvs].sort((pdvA, pdvB) =>
				pdvA.nome.localeCompare(pdvB.nome),
			)
			: []
	}

	get comandasOrdenados() {
		const comandas =
			this.loja && this.loja.comandas && this.comandasSelecionadas === null
				? [...this.loja.comandas].sort((comandaA, comandaB) =>
					comandaA.nome.localeCompare(comandaB.nome),
				)
				: this.comandasSelecionadas != null &&
				this.comandasSelecionadas.length > 0
					? [...this.comandasSelecionadas].sort((comandaA, comandaB) =>
						comandaA.nome.localeCompare(comandaB.nome),
					)
					: []

		return comandas
	}

	get podeEditar() {
		return this.edicaoGenerica.podeEditar
	}

	deletarMarcaDaLoja(marca: Marca) {
		const idx = this.loja?.marcas.findIndex(
			marcaLoja => marcaLoja.id === marca.id,
		)
		if (idx != undefined) this.loja?.marcas.splice(idx, 1)
	}

	updateListaDeMarca(marcasUpdate: Marca[]) {
		marcasUpdate.forEach(marca => {
			this.loja?.marcas.push(marca)
		})
	}

	@Watch('buscaPorNomeEIdenticador', { immediate: true })
	async bucarComanda() {
		if (!this.loja) return
		if (!this.buscaPorNomeEIdenticador) {
			this.comandasSelecionadas = null
			return
		}
		try {
			const comanda = await this.comandaUseCase.getComandas(
				this.buscaPorNomeEIdenticador,
				this.loja.id,
			)
			this.comandasSelecionadas = comanda.content
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async carregar() {
		try {
			this.carregando = true
			this.loja = this.novo
				? criarLoja()
				: await this.findUseCase.findLojaById(this.id)

			if(this.loja.id.length > 0) {
				this.configuracoesDeNotificacaoPorUsuario = await this.findUseCase.findConfigDeNotificacaoPorUsuario(this.id)
				this.configuracoesDeNotificacaoPorEmail = await this.findUseCase.findConfigDeNotificacaoPorEmail(this.id)
			}
			nextTick().then(() => {
				this.versaoAnterior = JSON.parse(JSON.stringify(this.loja))
			})
		} catch (error: any) {
			this.erro = error
			AlertModule.setError(error)
		} finally {
			this.$nextTick(() => {
				this.carregando = false
			})
		}
	}

	async abrirDialogo() {
		this.dialogo.mostrar()
	}

	redirecionarAba(campos: CamposDaImportacao[]) {
		if (campos.includes('pagamentos')) {
			this.tab = this.tabs.indexOf('Pagamentos')
			return
		}
	}

	validarFormularios() {
		for (let i = 0; i < this.tabs.length; ++i) {
			if (this.$refs[`form${i}`].validate()) continue
			this.tab = i
			return false
		}
		return true
	}

	@Watch('pdvSelecionado')
	onChangePdvSelecionado() {
		if (this.loja != undefined) {
			const pdvEncontrado = this.loja.pdvs.find(
				pdv => pdv.nome === this.pdvSelecionado,
			)

			if (pdvEncontrado != undefined) {
				this.loja.configuracaoDaLoja.pdvIntegracao = pdvEncontrado.id
			}
		}
	}

	get pdvSelecionadoFormatado() {
		if (!this.loja) return 'TESTE'

		const lojaAtual = this.loja

		const pdvFiltrado = this.pontosDeVendaDisponiveis.find(
			pdv => pdv.id === lojaAtual.configuracaoDaLoja.pdvIntegracao,
		)

		return pdvFiltrado ? pdvFiltrado.nome : ''
	}

	set pdvSelecionadoFormatado(nomePdv: string) {
		this.pdvSelecionado = nomePdv
	}

	async salvar() {
		if (!this.loja) return
		if (!this.validarFormularios()) return
		if (!this.loja.telefones || this.loja.telefones.length === 0) {
			this.tab = 1
			AlertModule.setError('Informe ao menos um telefone')
			return
		}

		if (this.loja.grupoEconomico && this.loja.grupoEconomico.id === '') {
			this.tab = 0
			AlertModule.setError('Informe um grupo econômico')
			return
		}

		try {
			this.salvando = true
			
			const lojaSalva = await this.saveUseCase.save({
				...this.loja,
				razaoSocial: this.razaoSocialSemCaracteresEspeciais,
				tiposDePagamento: this.loja.tiposDePagamento.map(tipoDePagamento =>
					tipoDePagamentoTemRecorrencia(tipoDePagamento)
						? tipoDePagamento
						: { ...tipoDePagamento, diasDeRecorrencia: 0 },
				),
			})

			this.loja = lojaSalva
			AlertModule.setSuccess('Loja salva com sucesso')
			await nextTick()
			this.versaoAnterior = JSON.parse(
				JSON.stringify({
					...this.loja,
					cnpj: formatarCnpjOuCpf(this.loja.cnpj),
				}),
			)
			if (!this.novo) return
			this.$router.push({
				name: 'Loja',
				params: { id: lojaSalva.id },
			})
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	adicionarTelefone() {
		if (!this.loja) return
		this.loja.telefones.push('')
		this.$nextTick(() => {
			if (!this.loja) return
			const campo = this.camposDeTelefones[this.loja.telefones.length - 1]
			campo.$children[0].focus()
		})
	}

	inserirFuncionario(usuario: Usuario) {
		if (!this.loja) return
		const jaEstaNaLoja = this.loja.usuarios.some(({ id }) => usuario.id === id)
		if (jaEstaNaLoja) {
			AlertModule.setError(
				`${usuario.nome} já consta no quadro de funcionários`,
			)
			return
		}
		this.loja.usuarios.push(usuarioParaOperador(usuario))
	}

	async excluirLoja() {
		try {
			this.excluindo = true
			await this.deleteUseCase.delete(this.id)
			await this.$router.push({ name: 'Lojas' })
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.excluindo = false
		}
	}

	displayPerfilTooltip(id: string) {
		const perfil = UsuariosModule.perfil(id)
		return perfil ? `${perfil.nome} - ` : ''
	}

	removerUsuarioDaLoja(id: string) {
		if (!this.loja) return
		this.loja.usuarios = this.loja.usuarios.filter(usuario => usuario.id !== id)
	}

	get folderName() {
		if (!this.loja?.cnpj) return ''

		const cnpj = this.loja?.cnpj.replace(/[^0-9]+/g, '')
		return `certificado-nfe/${cnpj}`
	}

	get temCnpj() {
		return !!this.loja?.cnpj
	}

	get nomeFantasiaSize() {
		return this.loja?.identificador ? 10 : 12
	}

	async beforeRouteLeave(to, from, next) {
		next(!(await this.edicaoGenerica.validarTransicao()))
	}
}
