import { render, staticRenderFns } from "./CardDeProduto.vue?vue&type=template&id=6f04fe18&scoped=true&"
import script from "./CardDeProduto.vue?vue&type=script&lang=ts&"
export * from "./CardDeProduto.vue?vue&type=script&lang=ts&"
import style0 from "./CardDeProduto.vue?vue&type=style&index=0&id=6f04fe18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f04fe18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VIcon,VImg,VResponsive,VSimpleCheckbox,VTooltip})
