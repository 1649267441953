import request from '@/common/request'
import { Page, NotaFiscal, FiltroDeCapaDaNota, ItemDaNotaResumido } from '@/models'
import { ParamsFindNotaFiscal } from '@/usecases/fiscal/adapter/NotaFiscalServiceAdapter'
import { FiltroDeDocumentosFiscais } from "@/models/relatorios/FiltroDeDocumentosFiscais"
import { DocumentoFiscal, TotalizadoresDeDocumentosFiscais } from "@/models/fiscal/DocumentoFiscal"

const BASE_URL = '/notas-fiscais'

export const find = (params?: ParamsFindNotaFiscal): Promise<Page<NotaFiscal>> =>
	request.get(`${BASE_URL}`, {
		params,
	})

export const listDocumentosFiscais = (params?: FiltroDeDocumentosFiscais): Promise<Page<DocumentoFiscal>> =>
	request.get(`${BASE_URL}/relatorio`,{
		params,
	})

export const getTotalDeDocumentosFiscais = (params?: FiltroDeDocumentosFiscais): Promise<TotalizadoresDeDocumentosFiscais> =>
	request.get(`${BASE_URL}/relatorio/totalizadores`,{
		params,
	})

export const getXmls = (params?: FiltroDeCapaDaNota): Promise<string[]> =>
	request.get(`${BASE_URL}/xmls`, {
		params,
	})

export const findReferenciaDaEntrada = (sku: string, lojaId: string, cnpjEmitente: string): Promise<ItemDaNotaResumido> =>
	request.get(`${BASE_URL}/entradas/item-resumido/${sku}?lojaId=${lojaId}&cnpjEmitente=${cnpjEmitente}`)

export const findNotaDeMesmoValor = (valor: string, destinatario: string,  params?: any): Promise<string> => 
	request.get(`${BASE_URL}/${valor.toString()}/destinatario/${destinatario}`, {
		params,
	})
export const findNotaByChNFe = (chaveDanfe: string): Promise<NotaFiscal> =>
	request.get(`${BASE_URL}/nota/${chaveDanfe}`)