





















































































































































































































































































































import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import SeletorDeProdutos from '@/components/produto/SeletorDeProdutos.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { ItemDaPreVenda, PreVenda } from '@/models/pre-venda/PreVenda'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { converterProdutoParaProdutoSimples, obterQuantidadePesadaNaBalanca } from '@/shareds/produto-shareds'
import { maiorOuIgualAZero, obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindClienteUseCase, FindConfiguracaoDaContaUseCase, FindFornecedorUseCase, FindLojaUseCase, FindProdutoUseCase } from '@/usecases'
import { ItemPreVendaUseCase } from '@/usecases/venda/ItemPreVendaUseCase'
import { PreVendaUseCase } from '@/usecases/venda/PreVendaUseCase'
import axios, { CancelTokenSource } from 'axios'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import VueQuagga from "vue-quaggajs"
import { DataOptions } from 'vuetify'
import DialogoDeCsvItemDaPreVenda from './DialogoDeCsvItemDaPreVenda.vue'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue'
import { Cliente, Fornecedor } from '@/models'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import SeletorDeFornecedorComBusca from '@/components/fornecedor/SeletorDeFornecedorComBusca.vue'

Vue.use(VueQuagga);

@Component({
	components: {
		SeletorDeProdutos,
		Confirmacao,
		SeletorDeLojasDoUsuario,
		DialogoDeCsvItemDaPreVenda,
		SeletorDeCliente,
		CampoDeItemDeVenda,
		SeletorDeUsuario,
		SeletorDeFornecedorComBusca,
	},
})
export default class DialogoDeCadastroDaPreVenda extends Vue {
	@Ref() dialogoCsv!: DialogoDeCsvItemDaPreVenda
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda

	pesquisa = ''
	mostra = false
	loading = false
	carregando = false
	showCamera = false
	totalRegistros = -1
	buscandoProduto = false
	salvandoPreVenda = false
	editandoPreVenda = false
	lojaSelecionada: string | null = null
	carregandoItemNaPreVenda = false
	preVenda: PreVenda = criarPreVenda()
	itensDaPreVenda: ItemDaPreVenda[] = []
	itemDePreVenda: ItemDaPreVenda = criarItemPreVenda()
	valorLido: string | null = null
	cancelando: string[] = []
	cancelToken: CancelTokenSource | null = null
	mostrarDialogoObservacao = false
	mostrarDialogoSelecaoProduto = false
	mostrarDialogoLeituraDeCodigoBarras = false
	cliente: Cliente | null = null
	fornecedor: Fornecedor | null = null

	findClienteUseCase = new FindClienteUseCase()
	findFornecedorUseCase = new FindFornecedorUseCase()
	preVendaUseCase = new PreVendaUseCase()
	itemPreVendaUseCase = new ItemPreVendaUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()
	findLojaUseCase = new FindLojaUseCase()

	headers = [
		{ text: 'Produto', value: 'produto.nome' },
		{ text: 'Sku', value: 'produto.sku' },
		{ text: 'Ean', value: 'produto.eans' },
		{ text: 'Quantidade', value: 'quantidade' },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	tiposDePreVenda = [
		{ text: 'Venda', value: 'venda' },
		{ text: 'Demonstração', value: 'demonstracao' },
		{ text: 'Consignação', value: 'consignacao' },
		{ text: 'Brinde', value: 'brinde' },
		{ text: 'Outras entradas', value: 'outras entradas' },
		{ text: 'Outras saídas', value: 'outras saidas' },
		{ text: 'Conserto', value: 'conserto' },
		{ text: 'Ativo', value: 'ativo' },
		{ text: 'Devolução entrada', value: 'devolucao entrada' },
		{ text: 'Devolução saída', value: 'devolucao saida' },
	]

	readerSize = {
		width: 860,
		height: 480,
	}

	paginacao: DataOptions = {
		page: 1,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	async mostrar(preVendaSelecionada?: PreVenda, editando?: boolean, copiandoVenda?: boolean) {
		this.preVenda = preVendaSelecionada || criarPreVenda()
		this.editandoPreVenda = editando || false

		if(copiandoVenda) {
			this.cliente = this.preVenda.cliente || null
			this.itensDaPreVenda = this.preVenda.itens
			this.lojaSelecionada = this.preVenda.loja?.id || null
			if(this.preVenda.cpfOuCnpjDoCliente && this.preVenda.tipoDePreVenda === 'devolucao saida') {
				const fornecedoresBuscados = await this.findFornecedorUseCase.find({busca: this.preVenda.cpfOuCnpjDoCliente})

				if (fornecedoresBuscados.content.length > 0) {
					this.fornecedor = fornecedoresBuscados.content[0]
				}
			}
		} else {
			if(this.preVenda.id || editando) {
				if(this.preVenda.cpfOuCnpjDoCliente && this.preVenda.tipoDePreVenda !== 'devolucao saida') {
					this.cliente = await this.findClienteUseCase.findClienteByCpfOuCnpj(this.preVenda.cpfOuCnpjDoCliente)
				}

				if(this.preVenda.cpfOuCnpjDoCliente && this.preVenda.tipoDePreVenda === 'devolucao saida') {
					const fornecedoresBuscados = await this.findFornecedorUseCase.find({busca: this.preVenda.cpfOuCnpjDoCliente})

					if (fornecedoresBuscados.content.length > 0) {
						this.fornecedor = fornecedoresBuscados.content[0]
					}
				}

				this.lojaSelecionada = this.preVenda.loja?.id || null
				this.buscarItensDaPreVenda()
			} else {
				this.salvarPreVenda()
			}
		}

		this.mostra = true
	}

	async concluir() {
		try {
			this.salvandoPreVenda = true
			await this.salvarPreVenda()
			this.salvandoPreVenda = false
		} catch(e) {
			this.salvandoPreVenda = false
			AlertModule.setError(e)
		}

		this.fechar()
		this.$emit('buscarPreVendas')
	}

	fechar() {
		this.mostra = false
		this.carregando = false
		this.totalRegistros = -1
		this.itensDaPreVenda = []
		this.lojaSelecionada = null
		this.cliente = null
		this.fornecedor = null
		this.buscandoProduto = false
		this.preVenda = criarPreVenda()
		this.mostrarDialogoObservacao = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoLeituraDeCodigoBarras = false
		this.paginacao = {
			page: 1,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}
	}

	async cancelar() {
		this.lojaSelecionada = null
		this.mostra = false
		this.carregando = false
		this.totalRegistros = -1
		this.itensDaPreVenda = []
		this.buscandoProduto = false
		this.mostrarDialogoObservacao = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoSelecaoProduto = false
		this.mostrarDialogoLeituraDeCodigoBarras = false
		this.paginacao = {
			page: 1,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}

		try {
			if(this.preVenda.id && !this.editandoPreVenda) {
				await this.preVendaUseCase.remover(this.preVenda.id)
			}
		} catch(e) {
			AlertModule.setError(e)
		}		
	}

	@Watch('paginacao')
	async buscarItensDaPreVenda() {
		if(!this.preVenda.id) return
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const params = {
				idPreVenda: this.preVenda.id || null,
				pesquisa: this.pesquisa,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				sort: 'createdAt,desc',
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const listaDeItensDaVenda = await this.itemPreVendaUseCase.findItensDaPreVenda(params, axiosConfig)
			this.itensDaPreVenda = listaDeItensDaVenda.content
			this.totalRegistros = listaDeItensDaVenda.totalElements
			this.carregando = false
		} catch(e) {
			this.carregando = false
			AlertModule.setError(e)
		}
	}

	@Watch('lojaSelecionada')
	onChangeLojaSelecionada(lojaAtual, lojaAnterior) {
		if (!lojaAnterior) {
			this.salvarPreVenda()
		}
	}

	async salvarPreVenda() {
		if(!this.lojaSelecionada) return

		this.salvandoPreVenda = true

		try {
			this.preVenda.loja = await this.findLojaUseCase.findLojaById(this.lojaSelecionada)
			const preVendaCriada = await this.preVendaUseCase.salvar(this.preVenda)
			this.preVenda = preVendaCriada
		} catch(e) {
			AlertModule.setError(e)
		} finally {
			this.salvandoPreVenda = false
		}
	}

	@Watch('cliente', { deep: true })
	setCpfOuCnpjDoClienteNaPreVenda() {
		if(this.cliente && this.cliente.cnpjOuCpf === this.preVenda.cpfOuCnpjDoCliente) return

		this.preVenda.cpfOuCnpjDoCliente = this.cliente 
			? this.cliente?.cnpjOuCpf 
			: null
	}

	@Watch('fornwecedor', { deep: true })
	setCnpjDoFornecedorNaPreVenda() {
		if(this.fornecedor && this.fornecedor.cnpjOuCpf === this.preVenda.cpfOuCnpjDoCliente) return

		this.preVenda.cpfOuCnpjDoCliente = this.fornecedor 
			? this.fornecedor?.cnpjOuCpf 
			: null
	}

	async atualizarListaDeProdutos(item: ItemDaPreVenda) {
		this.carregandoItemNaPreVenda = true
		this.preVenda.itens = this.preVenda.itens ?? [];
		const existeItemNaLista = this.itensDaPreVenda.findIndex(itemLista =>
			itemLista.produto.id === item.produto.id,
		);

		try {
			if (this.totalRegistros >= 500 && existeItemNaLista === -1) {
				throw new Error('A solicitação excede o limite de 500 produtos')
			}

			if (!this.preVenda.id) {
				throw new Error('Pre venda não existe')
			}
			
			await this.itemPreVendaUseCase.salvarOuAtualizarItemPreVenda(item, this.preVenda.id)
			this.mostrarDialogoSelecaoProduto = false
			this.carregandoItemNaPreVenda = false
		} catch (error: any) {
			AlertModule.setError(error);
			this.mostrarDialogoSelecaoProduto = false
			this.carregandoItemNaPreVenda = false
		} finally {
			this.buscarItensDaPreVenda();
		}
	}

	async removerItemDaPreVenda(idItem: string) {
		if(!idItem) return
		this.cancelando.push(idItem)
		try {
			await this.itemPreVendaUseCase.remover(idItem)
			this.cancelando = this.cancelando.filter(id => idItem !== id)
			this.buscarItensDaPreVenda()
		} catch(e) {
			AlertModule.setError(e)
		}
	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoPorIdentificador(this.valorLido)

				const configuracaoDeConta = await this.findConfiguracaoDaContaUseCase.find()

				const quantidadeDeItens =
					produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida != 'MT'
						? 1
						: obterQuantidadePesadaNaBalanca(
							'',
							configuracaoDeConta?.medida || 6,
							configuracaoDeConta?.eans || 7,
							configuracaoDeConta?.casasNumericas || 4,
						)

				const item: ItemDaPreVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: quantidadeDeItens,
					preco: produto.preco || 0,
					chaveNotaOrigem: '',
				}

				this.atualizarListaDeProdutos(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}

	get usuario() {
		return UserLoginStore.usuario
	}
}

function criarPreVenda(): PreVenda {
	return {
		id: undefined,
		identificador: '',
		situacao: 'Aberto',
		dataHoraCriacao: null,
		itens: [],
		loja: null,
		cpfOuCnpjDoCliente: null,
		vendedor: null,
		tipoDePreVenda: 'venda',
		vendaIdOrigem: '',
	}
}

function criarItemPreVenda(): ItemDaPreVenda {
	return {
		id: '',
		produto: {	
			id: '',
			nome: '',
			tipo: 'Padrão',
			preco: 0,
			pesoLiquido: 0,
			pesoBruto: 0,
			descontoVarejo: 0,
			eans: [],
			sku: null,
			identificadores:[],
			nomeCompleto: '',
			produtoDesativado: false,
			isValePresente: false,
			urlImagens: null,
			skuPai: null,
			estacao: null,
		},
		quantidade: 0,
		preco: 0,
		chaveNotaOrigem: '',
	}
}
