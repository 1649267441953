import { GrupoEconomico } from '@/models/GrupoEconomico'

export function criarGrupoEconomico(): GrupoEconomico {
	return {
		id: '',
		identificador: '',
		descricao: '',
		preferencialRelacionamentoComCliente: false,
		preferencialUltimaCompra: true,
	}
}
