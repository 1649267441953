



















































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { NotasEmTransitoUseCase } from '@/usecases'

@Component
export default class DialogoDeValidacaoDaChave extends Vue {
	@Ref() form!: HTMLFormElement	
	
	notasEmTransitoUseCase = new NotasEmTransitoUseCase()

	mostra = false			
	serverError: any = null

	erroChave = ''	
	chaveInserida = ''
	
	loading = false	
	showCamera = false
	buscandoProduto = false
	mostraConsultaDeProduto = false
	carregandoCardDeVerificacao = false	
	mostrarDialogoLeituraDeCodigoBarras = false
	
	valorLido: string | null = null
	
	created() {
		this.$parent.$on('fecharDialogoDeValidacao', this.esconder)
	}	

	mostrar() {
		this.mostra = true;		
	}	

	esconder() {
		this.mostra = false
		this.chaveInserida = ''
		this.erroChave = ''
	}
	
	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	readerSize = {
		width: 860,
		height: 480,
	}

	onSubmit() {
		this.validarChaveNF(this.chaveInserida)
	}	

	validarChaveNF(chaveInserida: string) {
		if (!this.chaveInserida) {
			AlertModule.setError('Insira uma nota!')
			return
		} 
		
		try {
			this.carregandoCardDeVerificacao = true			
					
			this.$emit('abrirConferenciaMercadoria', 'NFe' + chaveInserida)
		}  catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregandoCardDeVerificacao = false 			
		}		
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido) {
			try {			

				this.chaveInserida = 'NFe' + this.valorLido

				this.$emit('abrirConferenciaMercadoria', this.chaveInserida)

			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}
}
