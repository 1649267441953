
















































































































































































































import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import { ConsultaPrecoDeProduto, EstoqueEmGrade, ItemDaTabelaDePreco, Loja, Produto } from '@/models'
import { ItemDaVenda } from '@/models/venda/Venda'
import { formatarMoeda } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindEstoqueUseCase, FindLojaUseCase, FindProdutoUseCase, FindUsuarioUseCase } from '@/usecases'
import Component from 'vue-class-component'
import { Ref, Vue } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import vueTabulator from 'vue-tabulator'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import axios, { CancelTokenSource } from 'axios'
import copy from 'copy-to-clipboard'
import Decimal from 'decimal.js'
import { converterProdutoParaProdutoSimples } from '@/shareds/produto-shareds'
import VueQuagga from "vue-quaggajs"

Vue.use(vueTabulator, { name: 'vue-tabulator' })
Vue.use(VueQuagga);

@Component({
	components: {
		SeletorDeLojasDoUsuario,
		CampoDeItemDeVenda,
	},
})
export default class TelaDeDnaDoProduto extends Vue {
	@Ref() form0!: HTMLFormElement
	@Ref() table!: HTMLFormElement
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda
	cancelTokenEstoque: CancelTokenSource | null = null

	mostrarDialogoLeituraDeCodigoBarras = false
	buscandoProduto = false
	showCamera = false
	readerSize = {
		width: 860,
		height: 480,
	}

	formatarMoeda = formatarMoeda

	findProdutoUseCase = new FindProdutoUseCase()
	findUsuarioUseCase = new FindUsuarioUseCase()
	findEstoqueUseCase = new FindEstoqueUseCase()
	findLojaUseCase = new FindLojaUseCase()

	estoqueEmGrade: EstoqueEmGrade | null = null

	get dadosDaConsultaDeEstoque(): object[] {
		if (!this.estoqueEmGrade) return []

		const tamanhos: any[] = []
		const cores: any[] = []
		const lojas: string[] = []

		this.estoqueEmGrade.estoquesPorCorETamanho.forEach(estoquePorCorETamanho => {
			if (tamanhos.filter(tamanho => tamanho === estoquePorCorETamanho.tamanho).length === 0) {
				tamanhos.push(estoquePorCorETamanho.tamanho)
			}
		})

		this.estoqueEmGrade.estoquesPorCorETamanho.forEach(estoquePorCorETamanho => {
			if (cores.filter(cor => cor === estoquePorCorETamanho.cor).length === 0) {
				cores.push(estoquePorCorETamanho.cor)
			}
		})

		this.estoqueEmGrade.estoquesPorCorETamanho.forEach(estoquePorCorETamanho => {
			if (lojas.filter(loja => loja === estoquePorCorETamanho.nomeLoja).length === 0) {
				lojas.push(estoquePorCorETamanho.nomeLoja)
			}
		})

		const colunasDaTabela = ['nomeLoja', 'nome', 'sku', 'cor', ...tamanhos]

		const itensDaTabelaMapeado: object[] = []

		cores.forEach(cor => {
			if (!this.estoqueEmGrade) return {
				nome: '',
				sku: '',
				cor: '',
			}

			lojas.forEach(loja => {
				if (!this.estoqueEmGrade) return {
					nome: '',
					sku: '',
					cor: '',
				}

				const itemDaTabelaMapeado = {
					[colunasDaTabela[3]]: cor,
					[colunasDaTabela[2]]: this.estoqueEmGrade.sku,
					[colunasDaTabela[1]]: this.estoqueEmGrade.nomeProduto,
				}

				const tamanhosDaCor = this.estoqueEmGrade.estoquesPorCorETamanho.filter(estoquePorCorETamanho => estoquePorCorETamanho.cor === cor &&
					estoquePorCorETamanho.nomeLoja === loja)

				itemDaTabelaMapeado[colunasDaTabela[0]] = loja

				let indiceDeTamanho = 4
				tamanhosDaCor.forEach(tamanho => {
					itemDaTabelaMapeado[colunasDaTabela[indiceDeTamanho]] = {
						estoqueDisponivel: tamanho.estoqueDisponivel,
						sku: tamanho.sku,
					}
					indiceDeTamanho = indiceDeTamanho + 1
				})

				itensDaTabelaMapeado.push(itemDaTabelaMapeado)
			})
		})
		return itensDaTabelaMapeado
	}
	lojas: Loja[] | null = null
	carregando = false
	loading = false
	temSomenteUmaLojaVinculada = false
	consultaDePrecoDeProdutos: ConsultaPrecoDeProduto[] | null = null
	mostraConsultaDeProduto = false
	busca = ''
	totalRegistros = -1
	produtoBipado: Produto | null = null

	options = {
		data: this.dadosDaConsultaDeEstoque,
		minHeight: "311px",
		movableColumns: true,
		groupBy: 'nomeLoja',
		layout: 'fitColumns',
		persistence: {
			sort: true,
			filter: true,
		},
		persistenceID: 'examplePersitstance',
		placeholder: 'Sem dados para exibir',
		columns: this.colunasFormatadas,
	}

	tabs = this.mostrarEstoque ? ['Preços', 'Estoque'] : ['Preços']

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	async created() {
		if (!this.usuario) return
		this.temSomenteUmaLojaVinculada = await this.findUsuarioUseCase.existeSomenteUmaLojaVinculadaComUsuario(
			this.usuario.id,
		)
		if (this.temSomenteUmaLojaVinculada) {
			const lojaVinculada = await this.findUsuarioUseCase.findById(
				this.usuario.id,
			)
			this.lojas = lojaVinculada.lojas
		}
	}

	get usuario() {
		return UserLoginStore.usuario
	}

	get tab() {
		if (!this.$route.query.t) return 0
		const index = this.tabs.findIndex(tab => tab === this.$route.query.t)
		return index !== -1 ? index : null
	}

	set tab(value: number | null) {
		const tab = typeof value === 'number' ? this.tabs[value] : undefined
		this.$router
			.push({
				name: this.$route.name as string,
				query: { t: !value ? undefined : tab },
			})
			.catch()
	}

	get nomeDaTabelaDePreco() {
		if (!this.consultaDePrecoDeProdutos) return []
		return this.consultaDePrecoDeProdutosSemDuplicadas
	}

	get colunas() {
		if (this.dadosDaConsultaDeEstoque && this.dadosDaConsultaDeEstoque.length > 0) {
			const filtros = Object.keys(this.dadosDaConsultaDeEstoque[0])
			return filtros
		}
		return []
	}

	get ordenacaoDasCores() {
		return [
			{ cor: "PP", prioridade: 4 },
			{ cor: "P", prioridade: 5 },
			{ cor: "M", prioridade: 6 },
			{ cor: "G", prioridade: 7 },
			{ cor: "GG", prioridade: 8 },
		]
	}

	get colunasFormatadas() {
		const colunas = this.colunas.map(coluna => {
			if (coluna === 'nomeLoja') {
				return {
					title: 'Nome da Loja',
					field: coluna,
					sequencia: 0,
				}
			} else if (coluna === 'nome') {
				return {
					title: 'Nome Produto',
					field: coluna,
					sequencia: 1,
				}
			} else if (coluna === 'sku') {
				return {
					title: 'Referência',
					field: coluna,
					sequencia: 2,
				}
			} else if (coluna === 'cor') {
				return {
					title: 'Cor',
					field: coluna,
					sequencia: 3,
				}
			} else if (this.ordenacaoDasCores.filter(ordenacaoCor => ordenacaoCor.cor === coluna).length > 0) {
				const ordenacaoDaCor = this.ordenacaoDasCores.filter(ordenacaoCor => ordenacaoCor.cor === coluna)[0]

				return {
					title: ordenacaoDaCor.cor,
					field: coluna,
					sequencia: ordenacaoDaCor.prioridade,
					formatter: (cell) => {
						return cell.getValue().estoqueDisponivel
					},
					cellClick: (e, cell) => { this.selecionarCelula(cell) },
				}
			} else {
				return {
					title: coluna,
					field: coluna,
					sequencia: Number.MAX_VALUE,
					formatter: (cell) => {
						return cell.getValue().estoqueDisponivel
					},
					cellClick: (e, cell) => { this.selecionarCelula(cell) },
				}
			}
		})

		colunas.sort(this.ordenar)

		return colunas.map(coluna => {
			const { sequencia, ...colunaSemSequencia } = coluna
			return colunaSemSequencia
		})
	}

	get consultaDePrecoDeProdutosSemDuplicadas() {
		if (!this.consultaDePrecoDeProdutos) return []
		const consultaDePrecoDeProdutosFormatado: ConsultaPrecoDeProduto[] = []
		this.consultaDePrecoDeProdutos.forEach(consultaDePrecoDeProduto => {
			if (this.consultaDePrecoDeProdutos === null) return

			const naoExisteNaLista = consultaDePrecoDeProdutosFormatado.filter(consultaDePrecoDeProdutoFormatado =>
				consultaDePrecoDeProdutoFormatado.nomeDaTabela === consultaDePrecoDeProduto.nomeDaTabela).length === 0

			if (naoExisteNaLista) {
				consultaDePrecoDeProdutosFormatado.push(consultaDePrecoDeProduto)
			}
		})

		return consultaDePrecoDeProdutosFormatado
	}

	selecionarCelula(celula) {
		if (celula.getValue().sku !== '') {
			copy(celula.getValue().sku)
			AlertModule.setSuccess(`Sku ${celula.getValue().sku} copiado para a área de transferência`)
		} else {
			AlertModule.setSuccess(`Variação não possui estoque cadastrado`)
		}
	}

	get mostrarEstoque() {
		return UserLoginStore.permiteRegraDeNegocio('exibir-estoque-na-tela-de-consulta-de-preço')
	}

	focarBusca() {
		setTimeout(() => {
			this.campoDeBusca.focus()
		})
	}

	ordenar(a, b) {
		return a.sequencia - b.sequencia;
	}

	async buscar(item: ItemDaVenda) {
		this.loading = true;
		let listaDeLojas = this.lojas
		if (!listaDeLojas || listaDeLojas.length < 1) {
			listaDeLojas = await this.findLojaUseCase.findLojasVinculadasAsLojasDoUsuario();
		}
		
		// Executa as consultas de forma independente
		this.consultarPrecoDoProduto(item, listaDeLojas);
		this.consultarEstoqueDeProdutos(item, listaDeLojas);
	}

	async consultarEstoqueDeProdutos(item: ItemDaVenda, lojas: Loja[]) {
		try {
			
			if (this.cancelTokenEstoque) this.cancelTokenEstoque.cancel();
			this.cancelTokenEstoque = axios.CancelToken.source();
			const axiosConfig = {
				cancelToken: this.cancelTokenEstoque.token,
			};

			this.estoqueEmGrade = await this.findEstoqueUseCase.findEstoqueEmGradePorProduto(
				{
					produtoId: item.produto.id,
					lojasId: lojas.map(loja => loja.id),
				},
				axiosConfig,
			);

			this.options = {
				data: this.dadosDaConsultaDeEstoque,
				minHeight: "311px",
				movableColumns: true,
				layout: 'fitColumns',
				groupBy: 'nomeLoja',
				persistence: {
					sort: true,
					filter: true,
				},
				persistenceID: 'examplePersitstance',
				placeholder: 'Sem dados para exibir',
				columns: this.colunasFormatadas,
			};

			this.mostraConsultaDeProduto = true
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.loading = false
		}
	}

	async consultarPrecoDoProduto(item: ItemDaVenda, lojas: Loja[]) {
		try {
			this.produtoBipado = item.produto as Produto;
			const consultasDePrecoPromises = lojas.map(async (loja) => {
				const precosDoProduto = await this.findProdutoUseCase.findConsultaPrecoDeProduto(
					item.produto.id,
					loja.id,
				);
				return precosDoProduto;
			});
			const resultados = await Promise.all(consultasDePrecoPromises);
			this.consultaDePrecoDeProdutos = resultados.flat();
			this.options = {
				data: this.dadosDaConsultaDeEstoque,
				minHeight: "311px",
				movableColumns: true,
				layout: 'fitColumns',
				groupBy: 'nomeLoja',
				persistence: {
					sort: true,
					filter: true,
				},
				persistenceID: 'examplePersitstance',
				placeholder: 'Sem dados para exibir',
				columns: this.colunasFormatadas,
			};
			this.mostraConsultaDeProduto = true;
		} catch (e) {
			AlertModule.setError(e);
		} 
	}

	aplicarDesconto(item: ItemDaTabelaDePreco) {
		if (!item.descontoVarejo) return item.precoFinalVarejo

		const descontoValor = new Decimal(1).sub(new Decimal(item.descontoVarejo).div(100))
		const preco = new Decimal(item.precoFinalVarejo)
		const precoComDesconto = preco.mul(descontoValor).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
		return precoComDesconto.toNumber()
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true

		const valorLido = data.codeResult.code

		if (valorLido) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoPorIdentificador(valorLido)
				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: 1,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco||0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
					isDeOutraLoja: false,
				}

				this.buscar(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.mostrarDialogoLeituraDeCodigoBarras = false
			}
		}
	}
}
