import request from "@/common/request"
import { Page, NotaEmTransito, ItemDaNotaEmTransito, ItemDaNotaEmTransitoSalvo } from "@/models"
import { AxiosRequestConfig } from "axios"

const BASE_URL = "/notas-em-transito"

export const findNotasEmTransito = async (params?: any, config?: AxiosRequestConfig): Promise<Page<NotaEmTransito>> =>
	request.get(`${BASE_URL}`, { params, ...config })

export const receberNotaEmTransito = async (nota: NotaEmTransito): Promise<NotaEmTransito> =>
	request.post(`${BASE_URL}/receber-nota`, nota)

export const remover = async (params: any): Promise<void> =>
	request.delete(`${BASE_URL}/remover`, { params }) 

export const findByChaveDanfe = async (chaveDanfe: any): Promise<NotaEmTransito> =>
	request.get(`${BASE_URL}/chave-nota/${chaveDanfe}`)

export const getProdutosDaNotaEmTransito = async (chaveDanfe: any): Promise<ItemDaNotaEmTransito> =>
	request.get(`${BASE_URL}/produtos-nota/${chaveDanfe}`)

export const salvarProdutoBipadoDaNota = async (produto: any): Promise<ItemDaNotaEmTransitoSalvo> =>
	request.post(`${BASE_URL}/salvar-produto`, produto)

export const resetarBipagem = async (notaId: string |null): Promise<any> =>
	request.put(`${BASE_URL}/resetar-bipagem/${notaId}`)

export const salvarMotivoProdutoFaltante = async (produto: any): Promise<any> =>
	request.post(`${BASE_URL}/produto-bipado-nota/motivo-faltante`, produto)