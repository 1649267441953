















import { Vue, Component } from 'vue-property-decorator'
import DialogoDeConfiguracaoDePerfil from '@/views/application/usuarios/DialogoDeConfiguracaoDePerfil.vue'
import Help from '@/components/ui/Help.vue'
import DialogoDeDownload from '@/components/ui/DialogoDeDownload.vue'
import BotaoDeNotificacao from '@/components/notificacao/BotaoDeNotificacao.vue'
import BotaoDaTelaDeProcessos from '@/views/application/entradas/BotaoDaTelaDeProcessos.vue'
import AvisoDeNotificacao from '@/components/notificacao/AvisoDeNotificacao.vue'

@Component({
	components: {
		DialogoDeConfiguracaoDePerfil,
		Help,
		BotaoDeNotificacao,
		DialogoDeDownload,
		BotaoDaTelaDeProcessos,
		AvisoDeNotificacao,
	},
})
export default class IconesDaToolbarPadroes extends Vue {

}

