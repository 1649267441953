



























































































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { FormDeFechamentoDeCaixa, FormDeItemDeFechamento, Loja, TurnoDeVenda, Venda } from '@/models'
import ItemDaTabelaDeFechamento from './ItemDaTabelaDeFechamento.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import {  EditarPdvUseCase, FindLojaUseCase, FindTurnoDeVendaUseCase } from '@/usecases'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeTurnoEncerrado from '@/views/application/fechamentosdecaixa/DialogoDeTurnoEncerrado.vue'

@Component({
	components: {
		ItemDaTabelaDeFechamento,
		DialogoDeTurnoEncerrado,
	},
})
export default class DialogoDeFechamentoDeCaixa extends Vue {
	@Ref() itensDaTabelaDeFechamento!: ItemDaTabelaDeFechamento[]
	@Ref() form!: HTMLFormElement
	@Prop() vendas!: Venda[]
	@Prop({ type: Boolean, default: false }) fecharAsCegas!: boolean
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Ref() dialogoDeTurnoEncerrado!: DialogoDeTurnoEncerrado

	fechamento: FormDeFechamentoDeCaixa | null = null
	loja: Loja | null = null
	findLojaUseCase = new FindLojaUseCase()
	findTurnoDeVendaUseCase = new FindTurnoDeVendaUseCase()
	loading = true
	fechandoCaixa = false
	findStatusPdv = new EditarPdvUseCase()

	@Watch('turnoDeVenda', { immediate: true })
	async onChangePdv(turnoDeVenda: TurnoDeVenda | null) {
		if (!turnoDeVenda) return
		const loja = (await this.findLojaUseCase.find()).content.find(loja =>
			loja.pdvs.some(pdv => pdv.id === turnoDeVenda.pontoDeVenda.id),
		)
		if (!loja) return
		this.loja = loja
	}

	get tiposDePagamentoAdicionais() {
		if (!this.turnoDeVenda) return []
		return this.turnoDeVenda.tiposDePagamento.filter(
			({ formaDePagamento }) => formaDePagamento !== 'Dinheiro',
		)
	}

	voltar() {
		this.$emit('voltar')
	}

	@Watch('syncMostra', { immediate: true })
	async onChangeMostra(mostra: boolean) {
		this.loading = true
		this.fechandoCaixa = false
		if (!mostra) return
		if (!this.turnoDeVenda || !this.turnoDeVenda.id) return
		try {
			this.fechamento =	await this.findTurnoDeVendaUseCase.getFechamentoEsperado(this.turnoDeVenda.id)
			this.loading = false

			setTimeout(() => {
				this.form.resetValidation()
				setTimeout(() => {
					this.itensDaTabelaDeFechamento[0].focus()
				})
			})
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async confirmarFechamento() {
		if (!this.form.validate()) return
		if (!this.turnoDeVenda || !this.turnoDeVenda.id) return
		if (!this.fechamento) return
		this.fechandoCaixa = true
		if(!this.idUsuarioLogado) return

		const bloqueiaEncerrarTurno = await this.findStatusPdv.findUltimoUsuarioLogado(this.turnoDeVenda.pontoDeVenda.id, this.idUsuarioLogado)
		
		if(bloqueiaEncerrarTurno){
			this.$emit('fecharOverlayCancelamentoDeVendasEmAndamento')
			localStorage.removeItem('TURNO_DE_VENDA_STORAGE')
			this.dialogoDeTurnoEncerrado.mostrar()
			return
		}

		const fechamento: FormDeFechamentoDeCaixa = {
			dataHoraFechamento: new Date(),
			itensDeFechamento: this.fechamento.itensDeFechamento
				.map<FormDeItemDeFechamento>(item => ({
					...item,
					valorContado: item.valorContado as number,
				})),
			situacaoAudicao: 'Pendente',
			observacaoAudicao: '',
			anexos: [],
		}
		this.$emit('input', fechamento)
		this.$emit('fecharOverlayCancelamentoDeVendasEmAndamento')
		localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(false));
	}

	get totalEsperado() {
		if (this.fazAsCegas || !this.fechamento) return '-'
		const total = this.fechamento?.itensDeFechamento.reduce(
			(total, { valorEsperado }) => total + Number(valorEsperado),
			0,
		)
		return formatarMoeda(total)
	}

	get totalContado() {
		if (!this.fechamento) return '-'
		if (
			this.fechamento.itensDeFechamento.some(
				({ valorContado }) => valorContado === null,
			)
		)
			return '-'
		const total = this.fechamento.itensDeFechamento.reduce(
			(total, { valorContado }) => total + Number(valorContado),
			0,
		)
		return formatarMoeda(total)
	}

	get totalDiferenca() {
		if (this.fazAsCegas || !this.fechamento) return '-'
		if (
			this.fechamento.itensDeFechamento.some(
				({ valorContado }) => valorContado === null,
			)
		)
			return '-'
		const total = this.fechamento.itensDeFechamento.reduce(
			(total, { valorContado, valorEsperado }) =>
				total + Number(valorContado) - Number(valorEsperado),
			0,
		)
		return formatarMoeda(total)
	}

	get fazAsCegas() {
		return (
			!this.fechamento ||
			!this.loja ||
			this.loja.configuracaoDaLoja.fazContagemAsCegas
		)
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	get dataHoraAbertura() {
		if (!this.turnoDeVenda) return ''
		const abertura = this.movimentacaoDeAbertura
		if (!abertura) return ''

		return dateToPtBrFormat(new Date(abertura.dataHoraMovimentacao))
	}

	get movimentacaoDeAbertura() {
		if (!this.turnoDeVenda) return ''
		return this.turnoDeVenda.movimentacoes.find(
			({ tipoMovimentacao }) => tipoMovimentacao === 'Abertura',
		)
	}

	obterValorPorPercentual(valor: number, percentual: number) {
		return ~~((valor*percentual)/100)
	}

	get idUsuarioLogado(): string | undefined {
		return UserLoginStore.usuario?.id || undefined
	}
}

