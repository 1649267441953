














































































































































































import {
	Vue,
	Component,
	Ref,
	Watch,
} from 'vue-property-decorator'
import {
	FechamentoDeCaixa,
	ItemDeFechamento,
	FormDeFechamentoDeCaixa,
	Page,
	Loja,
	Pageable,
	SituacaoAudicao,
} from '@/models'
import {
	FindFechamentoDeCaixaUseCase,
	SaveFechamentoDeCaixaUseCase,
	FindTurnoDeVendaUseCase,	
} from '@/usecases'
import type { LojaServiceAdapter } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import DialogoDeAuditoria from './DialogoDeAuditoria.vue'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import DialogoDeDetalhesDeFechamento from '@/components/venda/DialogoDeDetalhesDeFechamento/DialogoDeDetalhesDeFechamento.vue'
import SnackbarDeAuditoria from './SnackbarDeAuditoria.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import moment from 'moment'
import Axios, { CancelToken, CancelTokenSource } from 'axios'
import { formatarDataHora, formatarMoeda } from '@/shareds/formatadores'
import ImportadorDeArquivos from './ImportadorDeArquivos.vue'
import { Inject } from 'inversify-props'


const ULTIMO_FILTRO_DE_FECHAMENTO = 'ULTIMO_FILTRO_DE_FECHAMENTO'

export type FiltroDeFechamento = {
	lojaId: Loja | null
	pdvId: string | null
	datas: (string | null)[]
	situacoes: SituacaoAudicao[]
}

function obterFiltrosDeFechamentosIniciais(): FiltroDeFechamento {
	return {
		lojaId: null,
		pdvId: null,
		datas: [],
		situacoes: ['Pendente'],
	}
}

@Component({
	components: {
		SeletorDeLojas,
		RangeDatePicker,
		DialogoDeAuditoria,
		SnackbarDeAuditoria,
		ImportadorDeArquivos,
		DataTableDeCrudPaginado,
		DialogoDeDetalhesDeFechamento,
	},
})
export default class TelaDeFechamentos extends Vue {
	@Ref() dialogoDeAuditoria!: DialogoDeAuditoria
	@Ref() snackbarDeAuditoria!: SnackbarDeAuditoria
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeImportadorDeArquivos!: ImportadorDeArquivos

	headers = [
		{ text: 'Data e Hora', value: 'dataHoraFechamento' },
		{ text: 'PDV', value: 'turnoDeVenda.pontoDeVenda.nome' },
		{ text: 'Diferença (R$)', value: 'diferenca', class: ['text-right text-no-wrap'], width: '120px', sortable: false },
		{ text: 'Situação', value: 'situacaoAudicao' },
	]

	filtros = localStorage[ULTIMO_FILTRO_DE_FECHAMENTO]
		? JSON.parse(localStorage[ULTIMO_FILTRO_DE_FECHAMENTO])
		: obterFiltrosDeFechamentosIniciais()
	carregando = false
	formatarMoeda = formatarMoeda
	formatarDataHora = formatarDataHora
	fechamentos: FechamentoDeCaixa[] = []
	fechamentoSelecionado: FechamentoDeCaixa | null = null
	findTurnoDeVendaUseCase = new FindTurnoDeVendaUseCase()
	findFechamentoDeCaixaUseCase = new FindFechamentoDeCaixaUseCase()
	saveFechamentoDeCaixaUseCase = new SaveFechamentoDeCaixaUseCase()
	loja: Loja | null = null

	@Inject('LojaServiceAdapter')
	private lojaService!: LojaServiceAdapter
	
	buscandoLoja = false
	cancelToken: CancelTokenSource | null = null

	get lojaId() {
		return this.filtros.lojaId
	}

	created(){
		this.limparFiltros()
	}

	limparFiltros() {
		this.filtros = obterFiltrosDeFechamentosIniciais()
		this.aplicarFiltros()
	}

	@Watch('lojaId', { immediate: true })
	async onChangeLoja(lojaId: string | null, lojaIdAnterior: string | null) {
		if (!lojaId || (lojaIdAnterior !== undefined && lojaId !== lojaIdAnterior)) {
			this.filtros.pdvId = null
		}
		if (lojaId) {
			try {
				this.buscandoLoja = true
				if (this.cancelToken) this.cancelToken.cancel()
				this.cancelToken = Axios.CancelToken.source()
				this.loja = await this.lojaService.get(lojaId, {
					cancelToken: this.cancelToken.token,
				})
			} catch (e) {
				if (Axios.isCancel(e)) return
				AlertModule.setError(e)
			} finally {
				this.buscandoLoja = false
			}
		}
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		dataInicialDeFechamento?: string
		dataFinalDeFechamento?: string
		lojaId?: string
	}, cancelToken: CancelToken): Promise<Page<FechamentoDeCaixa>> {
		const pagina = await this.findFechamentoDeCaixaUseCase.find({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.fechamentos = pagina.content
		return pagina
	}

	aplicarFiltros() {
		localStorage[ULTIMO_FILTRO_DE_FECHAMENTO] = JSON.stringify(this.filtros)
		this.dataTablePaginado.reiniciar(this.filtrosFormatados)
	}

	get filtrosFormatados() {
		return {
			dataInicialDeFechamento: this.filtros.datas[0] && this.filtros.datas[1]
				? moment(this.filtros.datas[0]).toISOString(true)
				: undefined,
			dataFinalDeFechamento: this.filtros.datas[0] && this.filtros.datas[1]
				? moment(`${this.filtros.datas[1]} 23:59:59`).toISOString(true)
				: undefined,
			lojaId: this.filtros.lojaId || undefined,
			pdvId: this.filtros.pdvId || undefined,
			situacoes: this.filtros.situacoes,
		}
	}

	abrirDialogoDeAuditoriaDoFechamento(fechamentoDeCaixa: FormDeFechamentoDeCaixa) {
		this.dialogoDeAuditoria.mostrar(fechamentoDeCaixa)
	}

	async atualizarSituacaoAuditoriaDoFechamento(fechamentoDeCaixa: FormDeFechamentoDeCaixa) {
		try {
			this.carregando = true
			fechamentoDeCaixa.situacaoAudicao = 'Concluído'
			const fechamentoAtualizado = await this.saveFechamentoDeCaixaUseCase.update(fechamentoDeCaixa)
			this.atualizarFechamentoDeCaixa(fechamentoAtualizado)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	atualizarFechamentoDeCaixa(fechamentoDeCaixa: FechamentoDeCaixa) {
		if (!fechamentoDeCaixa) return
		const indice = this.fechamentos.findIndex(
			fechamentoExistente => fechamentoExistente.id === fechamentoDeCaixa.id,
		)
		if (indice === -1) return;
		
		this.fechamentos.splice(indice, 1, fechamentoDeCaixa);
	}

	get registrosFormatados() {
		return this.fechamentos.map(fechamentoDoCaixa => ({
			...fechamentoDoCaixa,
			diferenca: this.calcularDiferencaValoresDoCaixa(fechamentoDoCaixa.itensDeFechamento || []),
		}))
	}

	calcularDiferencaValoresDoCaixa(itens: ItemDeFechamento[]) {
		const sumValorEsperado = itens.reduce((a, b) => a + (b.valorEsperado || 0), 0)
		const sumValorContado = itens.reduce((a, b) => a + (b.valorContado || 0), 0)
		return sumValorContado - sumValorEsperado
	}

	get pdvsOrdenados() {
		return this.loja
			? [...this.loja.pdvs ].sort((pdvA, pdvB) => pdvA.nome.localeCompare(pdvB.nome))
			: []
	}
}
