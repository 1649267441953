

















































































































































































































































import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DataOptions } from 'vuetify'
import { FindProdutoUseCase, FindVendaUseCase, NotasEmTransitoUseCase } from '@/usecases'
import { ItemDeRomaneio } from '@/models/Romaneio'
import { obrigatorio } from '@/shareds/regras-de-form'
import { displayVariantes, converterProdutoParaProdutoSimples, displayNomeCompletoDoProduto } from '@/shareds/produto-shareds'
import { 	 
	ItemDaVenda, 
	ResumoDaVenda,		
	ItemDaNotaEmTransito,	 
} from '@/models'
import type { Loja, NotaEmTransito } from '@/models'

@Component({
	components:{
		CampoDeItemDeVenda,
	},
})
export default class DialogoDeConferenciaDeEntradaDeMercadoria extends Vue {
	@Ref() form!: HTMLFormElement		
	@Prop({ type: Object }) loja!: Loja		
	@Prop({ required: false }) nota!: NotaEmTransito	
	@Prop({ required: false }) chaveInserida!: string 

	notasEmTransitoUseCase = new NotasEmTransitoUseCase()
	
	eansDoPedido: string[] = []
	vendas: ResumoDaVenda[] = []	
	listaProdutosVerificados: string[] = []
	itemDaNotaEmTransito: ItemDaNotaEmTransito | null = null	
	
	notaEmTransito: NotaEmTransito | null = null
	chave: string | null = null

	recebendoNota = false
	mostra = false
	loading = false
	buscando = false	
	showCamera = false
	dialogoAberto = false
	buscandoProduto = false				
	carregandoCardDeVerificacao = false
	mostrarDialogoLeituraDeCodigoBarras = false	
	
	findVendaUseCase = new FindVendaUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	produtosBipados: Map<string, number> = new Map()	

	obrigatorio = obrigatorio	
	displayVariantes = displayVariantes
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	
	serverError: any = null
	valorLido: string | null = null			

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	readerSize = {
		width: 860,
		height: 480,
	}

	mostrar() {		
		this.mostra = true		
	}	
	
	abrirDialogoConfirmacao() {
		this.dialogoAberto = true
	}

	fecharDialogoConfirmacao() {
		this.dialogoAberto = false
	}

	esconder() {
		this.mostra = false
		this.chave = null		
		this.itemDaNotaEmTransito = null
		this.listaProdutosVerificados = []
		this.eansDoPedido = []
		this.produtosBipados.clear()
		this.produtosBipados.clear()				
		this.produtosBipados.clear()				
		this.chaveInserida = ''
		
	}		

	get itensAhVerificar() {
		if (!this.itemDaNotaEmTransito) return []		
		return Array.isArray(this.itemDaNotaEmTransito)
			? this.itemDaNotaEmTransito.map(item => ({
				...item,
				motivoSalvo: !!item.motivoFaltante?.trim(), 
			}))
			: [{ ...this.itemDaNotaEmTransito, motivoSalvo: !!this.itemDaNotaEmTransito.motivoFaltante?.trim() }]
	}

	get chaveDanfe() {
		return this.chave = this.chaveInserida ? this.chaveInserida : 'NFe' + this.nota.chaveDanfe
	}

	isProdutoVerificado(item: ItemDaNotaEmTransito) {
		return item.quantidadeBipada >= item.quantidade || (item.motivoFaltante && item.motivoFaltante.trim() !== "")
	}

	isProdutoVerificacaoIncompleta(item: ItemDaNotaEmTransito) {
		return item.quantidadeBipada > 0 && item.quantidadeBipada < item.quantidade && (!item.motivoFaltante || item.motivoFaltante.trim() === "")
	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}	

	get podeReceberNota() {
		return this.itensAhVerificar.every(
			item => this.isProdutoVerificado(item) || item.motivoFaltante,
		)
	}	

	async buscaItensDaNota(chaveDanfe: string) {
		try {			
			this.carregandoCardDeVerificacao = true		

			if (!chaveDanfe) return				
			try {
				const notaEncontrada = await this.notasEmTransitoUseCase.findByChaveDanfe(chaveDanfe)

				if (notaEncontrada.status === 'Recebido') {
					AlertModule.setError("A nota em transito já foi recebida!")
					setTimeout(() => {this.esconder()}, 3000)
					return
				}
				this.notaEmTransito = notaEncontrada
			} catch (e) {
				AlertModule.setError("Erro ao buscar nota.")
				console.error(e)
			}	

			try {					
				const itens = await this.notasEmTransitoUseCase.getProdutosDaNotaEmTransito(chaveDanfe)			
				this.itemDaNotaEmTransito = itens				
			} catch (e) {
				AlertModule.setError("Erro ao buscar produtos da nota.")
				console.error(e)
			}
			
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregandoCardDeVerificacao = false			
		}		
	}	

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido && this.loja.id) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.loja.id,
				)

				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: 0,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco || 0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
					isDeOutraLoja: false,
				}

				if(!this.eansDoPedido.includes(this.valorLido)) {
					AlertModule.setError("Produto não encontrado no pedido!")
				} 

				if(this.eansDoPedido.includes(this.valorLido)) {
					AlertModule.setSuccess("Produto encontrado no pedido!")
				}

				this.incluirItensNaTabela(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}		

	async incluirItensNaTabela(item: ItemDaVenda) {
		this.loading = true			

		try {
			const itemBipado: ItemDeRomaneio = {
				id: '',
				produto: {
					id: item.produto.id,
					nome: displayNomeCompletoDoProduto(item.produto),
					tipo: item.produto.tipo,
					preco: item.produto.preco,
					eans: item.produto.eans,
					sku: item.produto.sku || '',
					identificadores: item.produto.identificadores,
					nomeCompleto: item.produto.nomeCompleto,
					produtoDesativado: item.produto.produtoDesativado,
					descontoVarejo: item.produto.descontoVarejo,
					pesoBruto: item.produto.pesoBruto,
					pesoLiquido: item.produto.pesoLiquido,
					isValePresente: item.produto.isValePresente,
					urlImagens: item.produto.urlImagens,
					skuPai: item.produto.skuPai,
					estacao: item.produto.estacao,
				},
				quantidade: 1,
				dataHoraCriacao: new Date(),
			}			

			const produtoLidoPeloCodigo = itemBipado.produto.id
        
			for (const itens of this.itensAhVerificar) {
				this.eansDoPedido.push(itens.id)

				if (produtoLidoPeloCodigo === itens.id) {
					if(itens.quantidadeBipada >= itens.quantidade) {
						AlertModule.setError(`Produto ${itens.nome} completamente verificado!`)
						return
					}
					
					this.salvarProdutoBipado(itens)
					
				}
			}

			const todosProdutosVerificadosPresentes = this.itensAhVerificar.every(item => 
				this.listaProdutosVerificados.includes(item.id))

			if (todosProdutosVerificadosPresentes) {
				this.showCamera = false
				this.mostrarDialogoLeituraDeCodigoBarras = false							
			}
			
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.loading = false
		}
	}

	async salvarProdutoBipado(itemBipado: ItemDaNotaEmTransito) {
		if (!itemBipado) return
		try {
			this.loading = true

			const item: any = {				
				produtoId: itemBipado.id,			
				quantidadeBipada: itemBipado.motivoFaltante?.trim() ? 0 : 1,
				motivoFaltante: itemBipado.motivoFaltante?.trim() || null,
				notaEmTransito: { id: this.notaEmTransito?.id },
			}

			await this.notasEmTransitoUseCase.salvarProdutoBipadoDaNota(item)			

			if (!itemBipado.motivoFaltante) {
				AlertModule.setSuccess(`Produto ${itemBipado.nome} salvo com sucesso!`)
			} else if (itemBipado.motivoSalvo || itemBipado.quantidadeBipada >= itemBipado.quantidade) {
				AlertModule.setError('Item já verificado!')
			} else {
				AlertModule.setSuccess('Motivo salvo com sucesso!')
			}
			
			itemBipado.motivoSalvo = !!itemBipado.motivoFaltante?.trim()
		
			this.buscaItensDaNota(this.chaveDanfe)
		} catch (error: any) {
			const mensagemErro = error?.message || 'Ocorreu um erro ao salvar o produto.'
			AlertModule.setError(mensagemErro)
		} finally {
			this.loading = false
		}
	}

	async resetarBipagem() {
		try {
			this.loading = true

			if (this.notaEmTransito?.id) {
				await this.notasEmTransitoUseCase.resetarBipagem(this.notaEmTransito.id)
			} else {
				AlertModule.setError("Nota não encontrada.")
			}		
			
			this.produtosBipados = new Map()

			await this.buscaItensDaNota(this.chaveDanfe)
		} catch (e) {
			AlertModule.setError("Erro ao resetar produtos.")
			console.error(e)
		} finally {
			this.loading = false
		}
	}	

	async receberNota() {
		this.recebendoNota = true

		this.fecharDialogoConfirmacao()
		if (!this.notaEmTransito) {
			AlertModule.setError('nota em trânsito inválida')
			return
		}

		switch (this.notaEmTransito?.status) {
			case 'Processando':
				AlertModule.setError('A nota já está em fase de processamento')
				return
			case 'Recebido':
				AlertModule.setError(
					'A nota já foi recebida no sistema e não é possível realizar um segundo recebimento',
				)
				return
		}
		
		try {
			
			await this.notasEmTransitoUseCase.receberNotaEmTransito(this.notaEmTransito)
			
			AlertModule.setInfo(
				'A nota está sendo processada e você será notificado assim que o processo for concluído',
			)
			this.$emit('atualizarNotas', true)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.recebendoNota = false
			this.esconder()
		}
	}	

	@Watch('mostra')
	onChangeMostra(){
		this.buscaItensDaNota(this.chaveDanfe)
	}	
}
