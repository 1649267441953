

















































































































































































































import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import { NotaEmTransito, Fornecedor, ResumoDaVenda } from '@/models'
import { TipoDeOperacao } from '@/models/Recurso'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { formatarMoeda } from '@/shareds/formatadores'
import { sortConverter } from '@/shareds/paginacao'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindVendaUseCase, NotasEmTransitoUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import SeletorDeFornecedorComBusca from '@/components/fornecedor/SeletorDeFornecedorComBusca.vue'

import DialogoDeConferenciaDeEntradaDeMercadoria from '@/components/notas/DialogoDeConferenciaDeEntradaDeMercadoria.vue'
import DialogoDeValidacaoDaChave from '@/components/notas/DialogoDeValidacaoDaChave.vue'

@Component({
	components: {
		DataTableDeCrud,
		RangeDatePicker,
		SeletorDeLojas,
		Confirmacao,
		SeletorDeLojasDoUsuario,
		SeletorDeFornecedorComBusca,
		DialogoDeConferenciaDeEntradaDeMercadoria,
		DialogoDeValidacaoDaChave,
	},
})
export default class TelaDeNotasEmTransito extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() confirmarCancelamento!: Confirmacao
	@Ref() dialogoDeConferenciaDeEntradaDeMercadoria!: DialogoDeConferenciaDeEntradaDeMercadoria
	@Ref() dialogoDeValidacaoDaChave!: DialogoDeValidacaoDaChave

	busca = ''
	buscando = false
	mostra = false
	chaveInserida: string |null = null

	carregando = false
	loading = false
	receberNotas: string[] = []
	imprimirNotas: string[] = []
	notas: NotaEmTransito[] = []
	notasSelecionadas: NotaEmTransito[] = []
	cancelToken: CancelTokenSource | null = null
	notasEmTransitoUseCase = new NotasEmTransitoUseCase()
	findVendaUseCase = new FindVendaUseCase()	
	chaveCopiada = false
	erroAoCopiarChave = false
	nota: NotaEmTransito | null = null
	venda: ResumoDaVenda[] = []

	obrigatorio = obrigatorio
	formatarMoeda = formatarMoeda

	filtro: FiltroDeNotasEmTransito = {
		notasEmTransitoIds: ['', ''],
		lojaId: '',
		status: [],
		datas: [],
		numeroNotaFiscal: null,
		serieFiscal: null,
		horas: ['', ''],
		fornecedor: null,
	}

	headers = [
		{ text: 'Fornecedor', value: 'nomeFantasiaDoEmitente' },
		{ text: 'CNPJ Fornecedor', value: 'cnpjEmitente' },
		{ text: 'Série', value: 'serie' },
		{ text: 'Número da NF', value: 'numeroNF' },
		{ text: 'Chave', value: 'chaveDanfe' },
		{ text: 'qtd Comercial', value: 'quantidadeComercial' },
		{ text: 'Valor Total NF', value: 'valorTotalNfe' },
		{ text: 'Data e hora', value: 'dataHoraEmissao' },
		{ text: 'Data e hora recebida', value: 'dataHoraRecebimento' },
		{ text: 'Dias em trânsito', value: 'diasEmTransito' },
		{ text: 'Status', value: 'status' },
		{ text: 'Error', value: 'log' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1		

	mounted() {

		let buscarAoIniciar = false
		if (this.$route.query.lojaId) {
			this.filtro.lojaId = this.$route.query.lojaId as string
			buscarAoIniciar = true
		}

		if (this.$route.query.numero) {
			this.filtro.numeroNotaFiscal = this.$route.query.numero as any
		}

		if (this.$route.query.serie) {
			this.filtro.serieFiscal = this.$route.query.serie as any
		}

		if (buscarAoIniciar) {
			this.buscar()
		}
	}

	abrirDialogoDeValidacao() {		
		this.dialogoDeValidacaoDaChave?.mostrar()		
	}

	abrirConferenciaMercadorias(chaveInserida: string | null) {
		this.chaveInserida = chaveInserida						
		this.dialogoDeConferenciaDeEntradaDeMercadoria?.mostrar()
		setTimeout(() => {this.dialogoDeValidacaoDaChave?.esconder()}, 3000)		
	}

	async buscar() {
		if (!this.form.validate()) {
			AlertModule.setError('O filtro de loja é obrigatório')
			return
		}
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const sorted = sortConverter(this.paginacao)

			if (this.isReverseData()) {
				this.filtro.datas.reverse()
			}
			const dataHoraInicial = this.gerarDataHora(
				this.filtro.datas[0],
				this.filtro.horas[0] || '00:00',
			)
			const dataHoraFinal = this.gerarDataHora(
				this.filtro.datas[1],
				this.filtro.horas[1] || '23:59',
			)

			const filtrosFormatados = {
				lojaId: this.filtro.lojaId,
				status: this.filtro.status,
				dataInicial: dataHoraInicial ? dataHoraInicial : undefined,
				dataFinal: dataHoraFinal ? dataHoraFinal : undefined,
				numeroNF: this.filtro.numeroNotaFiscal ? this.filtro.numeroNotaFiscal : undefined,
				serie: this.filtro.serieFiscal ? this.filtro.serieFiscal : undefined,
				fornecedor: this.filtro.fornecedor ? this.filtro.fornecedor.cnpjOuCpf : undefined ,
			}

			const params = {
				...filtrosFormatados,
				page: this.paginacao.page - 1,
				busca: this.busca || undefined,
				size: this.paginacao.itemsPerPage,
				sorted: sorted,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.notasEmTransitoUseCase.find(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.notas = page.content
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async receberNota(notaTransito: NotaEmTransito) {
		if (!notaTransito) {
			AlertModule.setError('nota em trânsito inválida')
			return
		}

		switch (notaTransito.status) {
			case 'Processando':
				AlertModule.setError('A nota já está em fase de processamento')
				return
			case 'Recebido':
				AlertModule.setError(
					'A nota já foi recebida no sistema e não é possível realizar um segundo recebimento',
				)
				return
		}

		const idx = this.notas.map(nota => nota.id).indexOf(notaTransito.id)
		try {
			this.receberNotas.push(notaTransito.id)
			const notaRecebida = await this.notasEmTransitoUseCase.receberNotaEmTransito(
				this.notas[idx],
			)
			this.notas.splice(idx, 1, notaRecebida)
			this.receberNotas = this.receberNotas.filter(id => notaTransito.id !== id)
			AlertModule.setInfo(
				'A nota já está sendo processada e você será notificado assim que o processo for concluído',
			)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.loading = false
		}
	}

	async imprimirNotaSemValorFiscal(notaTransito: NotaEmTransito) {
		if (!notaTransito) {
			AlertModule.setError('nota em trânsito inválida')
			return
		}

		try {
			this.imprimirNotas.push(notaTransito.id)

			await this.notasEmTransitoUseCase.imprimirNotaEmTransito(notaTransito)
			
			this.imprimirNotas = this.imprimirNotas.filter(
				id => notaTransito.id !== id,
			)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}


	get notasFormatadas() {
		return this.notas.map(nota => ({
			...nota,
			chaveDanfe: nota.chaveDanfe.slice(3),
			log: !nota.log ? '- - -' : nota.log,
			dataHoraEmissao: !nota.dataHoraEmissao
				? '- - -'
				: dateTimeToPtBrFormat(nota.dataHoraEmissao),
			diasEmTransito:
				nota.status == 'Em trânsito' ? nota.diasEmTransito : '- - -',
			dataHoraRecebimento: !nota.dataHoraRecebimento
				? '- - -'
				: dateTimeToPtBrFormat(nota.dataHoraRecebimento),
			valorTotalNfe: formatarMoeda(nota.valorTotalNfe),
			quantidadeComercial: formatarMoeda(nota.quantidadeComercial),
			nomeFantasiaDoEmitente: nota.nomeFantasiaDoEmitente || nota.razaoSocialEmitente,
		}))
	}

	isReverseData() {
		if (!this.filtro.datas[0] || !this.filtro.datas[1]) return false
		if (this.filtro.datas[1] < this.filtro.datas[0]) return true
	}

	gerarDataHora(
		data?: string | null,
		hora?: string | null,
	): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao() {
		if (this.filtro.lojaId === '') return
		this.buscar()
	}

	@Watch('busca')
	onChangeBusca() {
		this.paginacao.page = 1
		this.buscar()
	}

	statusColor(status: string) {
		if (status === 'Recebido') return 'success--text font-weight-bold'
		if (status === 'Em trânsito' || status === 'Atualização')
			return 'warning--text font-weight-bold'
		if (status === 'Processando') return 'info--text font-weight-bold'
		if (status === 'Inconsistente' || status === 'Cancelado') return 'error--text font-weight-bold'
	}

	copiarTexto(notaTransito: NotaEmTransito) {
		const textoParaCopiar = notaTransito.chaveDanfe
		navigator.clipboard
			.writeText(textoParaCopiar)
			.then(() => {
				this.chaveCopiada = true

				setTimeout(() => {
					this.chaveCopiada = false
				}, 3000)
			})
			.catch(() => {
				this.erroAoCopiarChave = true
			})
	}

	clickRemover() {
		if (this.filtro.lojaId === '' || !this.filtro.lojaId) {
			AlertModule.setError('Informe uma loja para remover as notas com status: Em Trânsito')
			return
		}

		this.filtro.status = ['Em trânsito'] as never[]

		this.confirmarCancelamento.mostrar()
	}

	get podeDeletarNotaEmTransito() {
		return this.podeRealizarOperacao('deletar')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'notas-em-transito' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'notas-em-transito'))
	}
	
	async removerTodosPorFiltros() {
		const notasIdsEmTransito = this.notasSelecionadas
			.filter(({ status }) => status === 'Em trânsito')
			.map(({ id }) => id)
	
		this.filtro.notasEmTransitoIds = notasIdsEmTransito
		try {
			if(this.notasSelecionadas.length > 0 && notasIdsEmTransito.length === 0) {
				throw new Error('Não é possível remover notas com status diferente de Em trânsito')
				
			} else if(this.notasSelecionadas.length === 0 && notasIdsEmTransito.length === 0) {
				throw new Error('Por favor, selecione pelo menos uma nota para remover')
			}
			const filtroFormatado ={
				...this.filtro,
				fornecedor: this.filtro.fornecedor ? this.filtro.fornecedor.cnpjOuCpf : undefined ,
			}
			await this.notasEmTransitoUseCase.remover(filtroFormatado)
			AlertModule.setSuccess("Notas excluidas com sucesso")
		} catch(e) {
			AlertModule.setError(e)
		} finally {
			this.buscar()
		}
	}

	limparFiltros() {
		this.filtro = {
			notasEmTransitoIds: ['', ''],
			lojaId: '',
			status: [],
			datas: [],
			numeroNotaFiscal: null,
			serieFiscal: null,
			horas: ['', ''],
			fornecedor: null,
		};
		this.notas = [];
		this.notasSelecionadas = [];
		this.paginacao.page = 1; 
	}

}
export interface FiltroDeNotasEmTransito {
			notasEmTransitoIds: string[] | null
			lojaId: string | null
			status: string[] | null
			datas: string[]
			numeroNotaFiscal: string | null
			serieFiscal: string | null
			horas: string[]
			fornecedor: Fornecedor | null
}
