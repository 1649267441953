
import { TipoDeRecurso } from "@/models/Recurso";
import { tituloDaToolbarPeloNomeDaRota } from "@/shareds/router-shareds";
import { RouteConfig } from "vue-router";

const rotasDeFaq: RouteConfig[] = [
	{
		path: 'faq/novo',
		name: 'Nova Faq',
		components: {
			default: () => 
				import('@/views/application/faq/EdicaoDeFaq.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default: { novo: true },
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
	{
		path: 'faq/novo/:id',
		name: 'Editar Faq',
		components: {
			default: () => 
				import('@/views/application/faq/EdicaoDeFaq.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},	
		props: {
			default: true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
		meta: {
			recurso: 'faq' as TipoDeRecurso,
		},
	},
	{
		path: 'faq/:idFaq',
		name: 'Faq',
		components: {
			default: () => 
				import('@/views/application/faq/TelaDeFaqDetalhada.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			default:  true,
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},

	{
		path: 'faq',
		name: 'Faqs',
		components: {
			default: () => 
				import('@/views/application/faq/TelaDeFaq.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},

	{
		path: 'novidades',
		name: 'Novidades',
		components: {
			default: () => 
				import('@/views/application/novidades/TelaDeNovidades.vue'),
			toolbar: () =>
				import('@/components/layout/AppToolbarComTitulo.vue'),
		},
		props: {
			toolbar: tituloDaToolbarPeloNomeDaRota,
		},
	},
]
export default rotasDeFaq