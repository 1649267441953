import {
	FormProduto,
	FormProdutoBaseSimples,
	FormProdutoSimples,
	FormProdutoSimplesFilho,
	FormProdutoSimplesVariante,
	Produto,
	ProdutoComposto,
	ProdutoComVariantes,
	ProdutoPadrao,
	Variante,
	FiltroDeBuscaDeProdutos,
	ItemDoProdutoCompostoSimples,
	FormProdutoComposto,
} from './../models/Produto'

export function criarProduto(): FormProduto {
	return {
		tipo: 'Padrão',
		nome: '',
		ncm: null,
		cest: null,
		marca: null,
		tags: [],
		descricao: '',
		categoria: null,
		sku: null,
		preco: 0,
		pesoLiquido: null,
		pesoBruto: null,
		descontoVarejo: null,
		urlImagens: [],
		temGarantia: false,
		regrasFiscais: [],
		eans: [],
		unidadeDeMedida: 'UNID',
		produtoDesativado: false,
		produtoIntegrado: false,
		genero: null,
		fatorDeConversao: null,
		unidadeDeConversao: null,
		estacao: null,
		isValePresente: false,
		skuPai: null,
	}
}

export function obterFiltroDeBuscaDeProdutosDefault(): Pick<Required<FiltroDeBuscaDeProdutos>, 'ncms' | 'tags' | 'marcas' | 'skus' | 'eans' | 'categorias' | 'regimes'> {
	return {
		ncms: [],
		tags: [],
		marcas: [],
		skus: [],
		eans: [],
		categorias: [],
		regimes: [],
	}
}

export function obterFiltroDeBuscaDeSeletorDeProdutosDefault(): Pick<Required<FiltroDeBuscaDeProdutos>, 'skus' | 'identificador'> {
	return {
		skus: [],
		identificador: '',
	}
}

export const displayNomeCompletoDoProduto = (produto: Produto | FormProdutoSimples): string => {
	if (produto.nomeCompleto) return produto.nomeCompleto
	return produto.tipo === 'Variante'
		? `${produto.nome} ${displayVariantes(produto)}`
		: produto.nome
}

export const displayVariantes = (produto: Variante | FormProdutoSimplesVariante) => {
	return produto.atributosVariante.map(({ valor }) => valor).join(' / ')
}

export const listaDeUnidadesDeMedida = [
	'AMPOLA',
	'BALDE',
	'BANDEJ',
	'BARRA',
	'BISNAG',
	'BLOCO',
	'BOBINA',
	'BOMB',
	'CAPS',
	'CART',
	'CENTO',
	'CJ',
	'CM',
	'CM2',
	'CX',
	'CX2',
	'CX3',
	'CX5',
	'CX10',
	'CX15',
	'CX20',
	'CX25',
	'CX50',
	'CX100',
	'DISP',
	'DUZIA',
	'EMBAL',
	'FARDO',
	'FOLHA',
	'FRASCO',
	'GALAO',
	'GF',
	'GRAMAS',
	'JOGO',
	'KG',
	'KIT',
	'LATA',
	'LITRO',
	'M',
	'M2',
	'M3',
	'MILHEI',
	'ML',
	'MWH',
	'PACOTE',
	'PALETE',
	'PARES',
	'PC',
	'POTE',
	'K',
	'RESMA',
	'ROLO',
	'SACO',
	'SACOLA',
	'TAMBOR',
	'TANQUE',
	'TON',
	'TUBO',
	'UNID',
	'VASIL',
	'VIDRO',
]

export function converterProdutoParaProdutoPadrao(
	produto: FormProduto | ProdutoPadrao,
): ProdutoPadrao | FormProduto {
	return {
		...produto,
		tipo: 'Padrão',
	}
}

export function converterProdutoParaProdutoComVariantes(
	produto: FormProduto | ProdutoComVariantes,
): ProdutoComVariantes | FormProduto {
	return {
		...produto,
		tipo: 'Com Variantes',
		variantes: 'variantes' in produto ? produto.variantes : [],
		atributosProduto: 'atributosProduto' in produto ? produto.atributosProduto : [],
	}
}

export function converterProdutoParaProdutoComposto(
	produto: FormProduto | ProdutoComposto,
): ProdutoComposto | FormProduto {
	return {
		...produto,
		tipo: 'Composto',
		itens: 'itens' in produto ? produto.itens : [],
	}
}

function mesclarAtributosDaNovaVariante(
	varianteAnterior: Variante, varianteNova: Variante,
): Variante {
	return {
		...varianteAnterior,
		atributosVariante: varianteNova.atributosVariante,
	}
}

export function mesclarGradesDeAtributosVariantes(
	gradeAnterior: Variante[], gradeNova: Variante[],
): Variante[] {
	const gradeNovaMesclada = [...gradeNova]
	gradeAnterior.forEach(varianteAnterior => {
		const indice = gradeNova.findIndex((varianteNova) => {
			const temVarianteComum = varianteNova.atributosVariante.length <= varianteAnterior.atributosVariante.length &&
				varianteNova.atributosVariante.every(atributo =>
					varianteAnterior.atributosVariante.some(
						({ nome, valor }) => nome === atributo.nome && valor === atributo.valor,
					),
				)
			if (temVarianteComum) return true
			const temVarianteComAtributoAdicional = varianteNova.atributosVariante.length > varianteAnterior.atributosVariante.length &&
				varianteAnterior.atributosVariante.every(atributo =>
					varianteNova.atributosVariante.some(
						({ nome, valor }) => nome === atributo.nome && valor === atributo.valor,
					),
				)
			if (temVarianteComAtributoAdicional) return true
			return false
		})
		if (indice === -1) return

		const varianteNova = gradeNova[indice]
		const varianteMesclada = mesclarAtributosDaNovaVariante(varianteAnterior, varianteNova)
		gradeNovaMesclada.splice(indice, 1, varianteMesclada)
	})
	return gradeNovaMesclada
}

export function converterProdutoParaProdutoBaseSimples(produto: Produto | FormProdutoSimples): FormProdutoBaseSimples {
	return {
		id: produto.id,
		nome: produto.nome,
		preco: produto.preco as number,
		pesoLiquido: produto.pesoLiquido as number,
		pesoBruto: produto.pesoBruto as number,
		descontoVarejo: produto.descontoVarejo as number,
		tipo: produto.tipo,
		eans: produto.eans,
		identificadores: produto.identificadores,
		sku: produto.sku,
		produtoDesativado: false,
		isValePresente: produto.isValePresente,
		urlImagens: produto.urlImagens,
		skuPai: produto.skuPai,
		estacao: produto.estacao,
	}
}

export function converterVarianteParaVarianteSimples(produto: Variante): FormProdutoSimplesVariante {
	const produtoBase = converterProdutoParaProdutoBaseSimples(produto)
	return {
		...produtoBase,
		tipo: 'Variante',
		atributosVariante: produto.atributosVariante,
		produtoDesativado: produto.produtoDesativado,
		idProdutoPai: produto.idProdutoPai || '',
	}
}

export function converterProdutoParaProdutoSimples(produto: Produto | FormProdutoSimples): FormProdutoSimplesFilho {
	const produtoBase = converterProdutoParaProdutoBaseSimples(produto)
	switch (produto.tipo) {
		case 'Variante':
			return {
				...produtoBase,
				tipo: 'Variante',
				atributosVariante: produto.atributosVariante,
				produtoDesativado: produto.produtoDesativado,
				idProdutoPai: produto.idProdutoPai || '',
			}
		case 'Com Variantes':
			throw new Error('Tratamento de conversão para variante')
		case 'Composto':
			return {
				...produtoBase,
				tipo: 'Composto',
				itens: (produto as FormProdutoComposto).itens.map<ItemDoProdutoCompostoSimples>(item => ({
					...item,
					produto: converterProdutoParaProdutoSimples(item.produto),
				})),
			}
		default:
			return {
				...produtoBase,
				tipo: produto.tipo,
			}
	}
}

export function parseCodigoDeBarrasDaBalancaParaEan(codigoDeBarras: string): string {
	return codigoDeBarras.substr(0, 7)
}

export function obterQuantidadePesadaNaBalanca(codigoDeBarras: string, medida: number, eans: number, casasNumericas: number): number {
	if (!codigoDeBarras || codigoDeBarras.length < 13) return 1
	const zerosAcrecentados = parseInt("1".padEnd(casasNumericas, "0"))
	const pesoLidoDaBalanca = Number.isNaN(Number(codigoDeBarras.substr(eans, medida)))
		? 4
		: ((Number(codigoDeBarras.substr(eans, medida - 1))) / (zerosAcrecentados))

	return pesoLidoDaBalanca
}
