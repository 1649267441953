









































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatarMoeda } from '@/shareds/formatadores'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import {
	obterCnpjOuCpfDaVenda,
	obterDisplayClienteDaVenda,
} from '@/shareds/venda-shareds'
import type { NotaDaVenda, ResumoDaVenda } from '@/models'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'


@Component({})
export default class GridDeVenda extends Vue{
	@Prop({ type: Array, default: () => [] }) vendas!: ResumoDaVenda[]
	@Prop({ type: Object }) notas!: NotaDaVenda
	mapErrosSefaz = mapErrosSefaz
	formatarMoeda = formatarMoeda
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	obterCnpjOuCpfDaVenda = obterCnpjOuCpfDaVenda
	obterDisplayClienteDaVenda = obterDisplayClienteDaVenda

	headers = [
		{ text: 'Modalidade', value: 'modalidadeDeVenda' },
		{ text: 'Identificador', value: 'identificador' },
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'PDV', value: 'pontoDeVenda.nome', sortable: false },
		{ text: 'Data e Hora', value: 'dataHora' },
		{ text: 'Cliente', value: 'displayCliente', sortable: false },
		{ text: 'Tipo de Cliente', value: 'displayTipoDeCliente' , sortable: false },
		{ text: 'NF / Série', value: 'displayNfSerie', sortable: false },
		{ text: 'Último Status', value:'displayStatusDaNota', sortable: false },
		{ text: 'Total (R$)',  value:'displayTotalDaVenda', sortable: false },
		{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
	]

	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayNfSerie: this.displayNfSerie(venda),
			displayCliente: obterDisplayClienteDaVenda(venda),
			displayTipoDeCliente: this.displayTipoDeCliente(venda),	
			displayTotalDaVenda: this.displayTotalDaVenda(venda),
			displayStatusDaNota: this.displayStatusDaNota(venda),
			numeroDaNota: venda.notas[0] ? venda.notas[0].nnf : null,
			serieFiscal: venda.notas[0] ? venda.notas[0].serie : null,
		}))
	}

	displayTipoDeCliente(venda: ResumoDaVenda) {
		if(venda.cliente) {
			return venda.cliente.tipoDeClienteNome
		}
		return '---'
	}

	get computedHeaders() {
		return this.headers.filter(({ value }) => {
			if (value === 'displayTotalDaVenda') {
				return !this.ocultarColunaDeTotalDaVenda;
			} 
			if (value === 'displayTipoDeCliente') {
				return this.exibirColunaDeTipoDeCliente;
			}
			return true
		});
	}

	get exibirColunaDeTipoDeCliente() {
		return UserLoginStore.permiteRegraDeNegocio('exibir-tipo-de-cliente-tela-de-vendas')
	}
	
	get ocultarColunaDeTotalDaVenda() {
		return UserLoginStore.permiteRegraDeNegocio('ocultar-valor-das-vendas')
	}

	tipoDeTransacaoFormatada(item) {
		return `${item.tipoDeTransacao} ${item.tipoNota || ''}`
	}

	displayTotalDaVenda(venda: ResumoDaVenda) {
		if (!venda.total) return '0,00'
		return formatarMoeda(venda.total)
	}

	displayStatusDaNota(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota && mapErrosSefaz[nota.cstat]
			? venda.possuiNotaNaoEletronica ? 
				'Não eletrônica'
				:
				nota.cstat + ' - ' + mapErrosSefaz[nota.cstat]
			: nota?.cstat || ''
	}

	displayNfSerie(venda: ResumoDaVenda) {

		venda.notas.sort((a, b) => {
			if (a.cstat === '100' && b.cstat !== '100') return -1;
			if (a.cstat !== '100' && b.cstat === '100') return 1;
			return 0;
		});

		const nota = venda.notas[0];
		return nota
			? venda.possuiNotaNaoEletronica ? 
				`NE ${nota.nnf} / ${nota.serie}`
				:
				`${nota.modelo} ${nota.nnf} / ${nota.serie}`
			: '';
	}

	getTextColor(item) {
		if(item){
			switch (item.origem) {
				case 'Presencial':
					return this.$vuetify.theme.dark ? '#7BEB76' : '#76FF03'
				case 'Devolução':
					return this.$vuetify.theme.dark ? '#FF991F' : '#FF8A65'
				case 'Demonstração':
					return '#FFFFFF'
				case 'Ecommerce':
					return '#71a9fc'
				case 'Link':
					return '#E3C656'
				default:
					return '#E35FF5'
			}
		}
		else{
			return '#E35FF5'
		}
	}
	
	getRowColor(item){
		return (item.ultimoStatus ? ["100", "101", "102"].includes(item.ultimoStatus) : item.notas.filter(nota => ["100", "101", "102"].includes(nota.cstat)).length > 0)
			|| item.notas.length === 0 ? "" : "cancelada"
	}
}
