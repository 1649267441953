
























































import type { Venda } from '@/models'
import { Pageable } from '@/models'
import { Vue, Component, Ref, Watch, PropSync } from 'vue-property-decorator'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeEdicaoInformacoesAdicionais extends Vue {
	@PropSync('value', { type: Object }) venda!: Venda
	@Ref() campoDeModalidade!: HTMLInputElement
	@Ref() form!: HTMLFormElement

	mostra: boolean | null = null
	salvando = false

	obrigatorio = obrigatorio

	pageable: Pageable = {
		page: 0,
		size: 10,
	}
	vendaAlterada: Venda | null = null
	
	@Watch('mostra')
	onChangeMostra() {
		this.vendaAlterada = {
			infoAdicionais: this.venda.infoAdicionais,
		} as Venda
	}

	esconder() {
		this.mostra = false
	}

	async confirmarAlteracao() {
		this.venda.infoAdicionais = this.vendaAlterada ? this.vendaAlterada.infoAdicionais : null
		VendaModule.setVendaAtual(this.venda)
		this.mostra = false
	}
}
