

































































































































































































import { ItemDaVendaComReferencias, Venda, VendaComReferencias } from '@/models'
import { TipoDeProcesso } from '@/models/anexo/AnexoDosProcessos'
import { formatarMoeda } from '@/shareds/formatadores'
import { imprimirRomaneio, gerarComprovanteDeDemonstracao80mm } from '@/shareds/pedidos/impressao-de-romaneio'
import { contarItensDaVenda, obterTotalDoItemComTaxas, obterTotalDosItensDaVenda, obterTrocoDaVenda, obterValorAPagar } from '@/shareds/venda-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindVendaUseCase } from '@/usecases'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class DialogoDeImpressaoDeRomaneio extends Vue {
	
	vendaLocal = JSON.parse(localStorage.getItem('ULTIMA_VENDA') || '');
	syncMostra = false
	itensDaVenda: ItemDaVendaComReferencias[] = []
	vendaComReferencias: VendaComReferencias | null = null
	vendas: Venda | null = null

	findVendaUseCase = new FindVendaUseCase()

	obterTotalDoItemComTaxas = obterTotalDoItemComTaxas
	formatarMoeda = formatarMoeda
	obterTotalDosItensDaVenda = obterTotalDosItensDaVenda
	contarItensDaVenda = contarItensDaVenda

	get restante() {
		return obterValorAPagar(this.vendaLocal)
	}

	get troco() {
		return obterTrocoDaVenda(this.vendaLocal)
	}

	get totalDaVendaOuDevolucao() {		
		return obterTotalDoItemComTaxas(this.vendaLocal) + (this.restante < 0 ? 0 : this.restante) - this.troco
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	async novaVenda() {
		if (this.turnoDeVenda) {
			try{
				this.syncMostra = false
				localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(false));
			} catch(e) {
				AlertModule.setError(e)
			}
		}
	}

	created(){
		this.syncMostra = JSON.parse(localStorage.getItem('TELA_DE_IMPRESSAO_DE_ROMANEIO') || '');
		if(localStorage.getItem('TURNO_DE_VENDA_STORAGE') === null){
			localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(false));
		}
	}

	mostrar() {
		this.syncMostra = true

		if(!this.vendaLocal || this.vendaLocal == '') return

		localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(true));
		this.vendaLocal = JSON.parse(localStorage.getItem('ULTIMA_VENDA') || '');
	}

	async imprimirPdfA4Romaneio() {
		if (!this.vendaLocal) return

		this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.vendaLocal.id)
		const usuarioId = UserLoginStore.usuario?.id
		const qrData = `${this.extractDomain(window.location.href)}/anexo/${this.$route.fullPath.replace("/", "") as TipoDeProcesso}/${this.vendaLocal.id}/${usuarioId}`
		const produtosComReferencia = this.vendaComReferencias?.itens
		try {
			const vendaImpressao = await this.findVendaUseCase.get(this.vendaLocal.id)
			await imprimirRomaneio(vendaImpressao, qrData, produtosComReferencia)
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	async gerarComprovanteDeDemonstracao80mm() {
		if (!this.vendaLocal) return
		this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.vendaLocal.id)
		const usuarioId = UserLoginStore.usuario?.id
		const qrData = `${this.extractDomain(window.location.href)}/anexo/${this.$route.fullPath.replace("/", "") as TipoDeProcesso}/${this.vendaLocal.id}/${usuarioId}`
		const produtosComReferencia = this.vendaComReferencias?.itens
		try {
			const vendaImpressao = await this.findVendaUseCase.get(this.vendaLocal.id)
			await gerarComprovanteDeDemonstracao80mm(qrData, produtosComReferencia, vendaImpressao)
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	extractDomain(url) {
		let domain = url.replace(/^https?:\/\//, '')
		domain = domain.split('/')[0]
		domain = domain.split(':')[0]

		return domain;
	}
}
