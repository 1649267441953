



















































































































































import {  ImportarItemDaPreVendaUseCase } from '@/usecases'
import { Component, Ref, Vue } from 'vue-property-decorator' 
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DatePicker from '@/components/ui/DatePicker.vue'
import RangerTimePicker from '@/components/ui/RangerTimePicker.vue'
import { ItemDaPreVendaImportacao } from '@/models/pre-venda/PreVenda'
import { VueCsvImport } from 'vue-csv-import'



@Component({
	components: {
		DatePicker,
		RangerTimePicker,
		VueCsvImport,
	},
})
export default class DialogoDeCsvItemDaPreVenda extends Vue {
	@Ref() csvComponent!: VueCsvImport
	mostra = false
	tab: null | number = null
	tabs = [
		{ action: 'Importar', icon: 'mdi-file-upload' },
		{ action: 'Modelo', icon: 'mdi-file-download' },
	]
	importarItemDaPreVendaUseCase = new ImportarItemDaPreVendaUseCase()
	importando = false
	estadoDaSelecao = "Nenhum arquivo selecionado"  
	csv: ItemDaPreVendaImportacao[] | null = null

	camposCsvItemImportar = {
		skuOuEan:'skuOuEan', 
		quantidade: 'quantidade',
	}

	arquivo = 'File'

	preVendaId: string | null = null
	selecionado: boolean | null = null
	inputFile: HTMLInputElement | null = null
	time = null
	
	hover= false

	async mostrar(preVendaId: string | null){
		this.preVendaId = preVendaId
		this.mostra = true
	}

	cancelar() {
		this.importando = false
		this.csv = []
		this.arquivo = ''
		this.mostra = false
		this.csvComponent.sample =  null
		this.csvComponent.fileSelected = false
		this.csvComponent.hasHeaders = false
		this.csvComponent.map = {}
		if(this.inputFile!= null)
			this.inputFile.value = ''
	}

	async concluirImportacao(){
		if(!this.csv) return
		if (!this.preVendaId) return

		AlertModule.setInfo('Itens em processamento. Essa solicitação pode levar alguns minitos para finalizar')
		try {
			this.importando = true

			await this.importarItemDaPreVendaUseCase.importarItens(this.preVendaId, this.csv)
			
			this.importando = false
			this.csv = []
			this.arquivo = ''
			this.mostra = false
			this.csvComponent.sample =  null
			this.csvComponent.fileSelected = false
			this.csvComponent.hasHeaders = false
			this.csvComponent.map = {}
			if(this.inputFile != null) this.inputFile.value = ''
			this.$emit('concluiuImportacao')
		} catch (e) {
			this.importando = false
			AlertModule.setError(e)
		} 
	}

	clickCsv() {
		this.inputFile = document.querySelector('.form-control-file')
		if(this.inputFile != null)
			this.inputFile.click();
		
	}

	async baixarModelo() {
		try {
			const response: any = 'skuOuEan;quantidade'
			console.log(response)
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `modelo_importacao_pre_venda.csv`)
			document.body.appendChild(link)
			link.click()
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get arquivoSelecionado() {
		if(this.csvComponent.fileSelected){
			return "Arquivo selecionado, favor definir colunas"
		}
		return "Nenhum arquivo selecionado"
	}

}
