


































































































































import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator'
import { Desconto, Perfil, Venda } from '@/models'
import type { ItemDaVenda } from '@/models'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { menorQueZero, maiorQueZero, obrigatorio } from '@/shareds/regras-de-form'
import { FindPerfilUseCase, FindProdutoUseCase, FindUsuarioUseCase } from '@/usecases'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { Decimal } from 'decimal.js'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { v4 as uuidv4 } from 'uuid'
import AlterarQuantidadeLimiteDoProduto from '@/components/ui/AlterarQuantidadeLimiteDoProduto.vue'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'
import { obterTotalDaVenda, obterTotalDoItem, obterTotalDosItensDaVenda } from '@/shareds/venda-shareds'
import { formatarMoeda } from '@/shareds/formatadores'
import BotaoDeVendedorPorItem from './BotaoDeVendedorPorItem.vue'
import { PERFIL_LOGADO } from '@/common/constants'
import { getRequestError } from '@/helpers/axios-helpers'

@Component({
	components: {
		CampoDecimal,
		AlterarQuantidadeLimiteDoProduto,
		BotaoDeVendedorPorItem,
	},
})
export default class CamposDeItemDaVenda extends Vue  {
	@Prop({type: Boolean}) podeSerPositivo!: boolean
	@Prop({type: Boolean}) podeSerNegativo!: boolean
	@Prop({type: Boolean}) permissaoConcedida!: boolean | undefined
	@Prop({type: Boolean}) ehUmaDevolucao!: boolean | undefined
	@PropSync('value') item!: ItemDaVenda
	@Ref() campoDeQuantidade!: HTMLInputElement
	@Ref() campoDeDesconto!: HTMLInputElement
	@Ref() alterarQuantidadeLimiteDoProduto!: AlterarQuantidadeLimiteDoProduto

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()
	findProdutoUseCase = new FindProdutoUseCase()

	formatarMoeda = formatarMoeda
	menorQueZero = menorQueZero
	maiorQueZero = maiorQueZero
	obrigatorio = obrigatorio

	usuarioAutenticado = false
	podeAlterarCampoDeDesconto = false
	podeAlterarQuantidade = false
	mostrarDialog = false
	quantidade = 0
	pagina = 1
	quantidadeAnterior: number | null = null
	processando = false
	listaDeControle = [{ 
		indice: 0,
		quantidade: 0,
	}]
	headers = [
		{
			text: 'Produto',
			value: 'produto.nome',
			class: 'text-no-wrap',
			width: '100%',
		},
		{
			text: 'Código',
			value: 'displayEans',
			class: 'text-no-wrap',
			align: 'right',
		},
		{ text: 'Quantidade', value: 'quantidade', class: 'text-no-wrap' },
	]

	get dispositivoPequeno() {
		return this.$vuetify.breakpoint.width < 600
	}

	@Watch("mostrarDialog")
	changeItemVenda() {
		if(this.mostrarDialog)
			this.quantidade = this.item.quantidade
	}

	@Watch('item.quantidade')
	async onChangeCampoDeQuantidade(quantidadeAtual, quantidadeAnterior) {
		if(this.item.quantidade >= 0) {
			try {
				this.processando = true
				if (this.quantidadeAnterior == null) {
					const podeGerarEstoque = this.$route.fullPath === '/caixa' ?
						VendaModule.lojaDaVenda?.configuracaoDaLoja.podeGerarEstoqueNaVenda
						:
						VendaModule.lojaDaEntrada?.configuracaoDaLoja.podeGerarEstoqueNaVenda

					const eUmaDemonstracaoEReaberta = VendaModule.vendaAtual?.isVendaReaberta 
						&& (VendaModule.vendaAtual.isDemonstracao || VendaModule.vendaAtual?.isConsignado) 
						&& VendaModule.vendaAtual.itens.some(item => item.produto.id === this.item.produto.id)

					const produtosLiberados = UserLoginStore.autenticandoParaLiberarVendaSemEstoque

					if(!podeGerarEstoque && !this.item.autenticadoParaLiberarProdutoSemEstoque) {
						if(!this.podeLiberarProdutoSemEstoque && !eUmaDemonstracaoEReaberta && !produtosLiberados) {
							if(VendaModule.lojaDaVenda) {
								await this.findProdutoUseCase.validarProduto(this.item.produto.sku || '', VendaModule.lojaDaVenda.id, quantidadeAtual)
							} else if (VendaModule.lojaDaEntrada) {
								await this.findProdutoUseCase.validarProduto(this.item.produto.sku || '', VendaModule.lojaDaEntrada.id, quantidadeAtual)
							}
						}
					}
					this.quantidadeAnterior = null
				} else {
					this.quantidadeAnterior = null
				}
			} catch (error: any) {
				if (getRequestError(error) == 'Produto está sem quantidade em estoque' ||
					getRequestError(error) == `Produtos ${this.item.produto.sku} sem quantidade disponível em estoque` ||
					getRequestError(error) == 'Produto não possui estoque parametrizado') {
					AlertModule.setError(error)
				} else if(getRequestError(error) !== 'Produto está sem quantidade em estoque' ||
						getRequestError(error) !== `Produtos ${this.item.produto.sku} sem quantidade disponível em estoque`) {
					AlertModule.setError(error)
				} else {
					AlertModule.setError(error)
				}

				this.item.quantidade = quantidadeAnterior
				this.quantidadeAnterior = quantidadeAnterior
			} finally {
				this.processando = false
			}
		}
	}

	public focus() {
		this.campoDeQuantidade.focus()
	}

	get podeLiberarProdutoSemEstoque() {
		return UserLoginStore.permiteRegraDeNegocio('pode-liberar-produto-sem-estoque')
	}

	get podeConcederDesconto() {
		return (UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')) && 
			((this.isDemonstracaoReabertaSemNF && this.item.isCompra) || !this.isDemonstracaoReabertaSemNF && !this.item.possuiDescontoPorTabela) && 
			(
				(!this.venda.isDemonstracao && !this.venda.isConsignado) || (this.venda.isVendaReaberta && this.item.isCompra || this.venda.notas.length > 0)
			)
	}

	get podeAlterarQuantidadeProduto() {
		return UserLoginStore.permiteRegraDeNegocio('informar-quantidade-de-produtos')
	}

	get podeAlterarQuantidadeSemAutenticacao() {
		return UserLoginStore.permiteRegraDeNegocio('pode-alterar-quantidade-de-produto')
	}

	get podeAlterarPrecoDoProduto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-alterar-preco-do-produto')
	}

	get isDemonstracaoReabertaSemNF() {
		return this.venda.isVendaReaberta && this.venda.isDemonstracao && this.venda.notas.length === 0
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	//todo EDU
	/*get descontoEValido() {
		return () => {
			if (!this.item.desconto) return true
			if (!this.item.desconto.isPercentual && this.item.desconto.valor > ((new Decimal(this.item.preco).mul(this.item.quantidade).toNumber()) - 0.01)) {
				return 'Valor maior que o total da venda'
			}

			if (this.item.desconto.isPercentual && this.item.desconto.valor > 99.99) {
				return 'Valor não pode ser maior que 99.99%'
			}

			const perfil: null | Perfil = localStorage['PERFIL_LOGADO']
				? JSON.parse(localStorage['PERFIL_LOGADO'])
				: null
			let valorBruto = 0
			let valorTotalDesconto = 0
			let percentualDeDescontoDaVenda = 0

			if (!perfil) {
				return 'Perfil não encontrado'
			}

			if (!this.item.possuiDescontoPorTabela && this.item.desconto.valor > 0 && !this.podeConcederDesconto) {
				return 'Sem permissão para conceder desconto'
			}
			const valorBrutoItem = this.item.preco * this.item.quantidade
			const valorLiquidoItem = this.validarTotalDoItem(this.item)

			if (this.item.possuiDescontoPorTabela) {
				valorBruto = valorBruto + valorLiquidoItem
			} else {
				valorBruto = valorBruto + valorBrutoItem
				valorTotalDesconto = Math.round((valorTotalDesconto + (valorBrutoItem - valorLiquidoItem)) * 100) / 100
			}

			if (this.podeConcederDesconto && perfil.percentualMaximoDescontoPorItem > 0) {
				percentualDeDescontoDaVenda = Math.round(((valorTotalDesconto/valorBruto)*100) * 100) / 100
				const valorPermitidoDeDesconto = valorBruto*perfil.percentualMaximoDescontoPorItem/100
				const valorPermitidoEmReal = formatarMoeda(valorPermitidoDeDesconto)
				if (percentualDeDescontoDaVenda > perfil.percentualMaximoDescontoPorItem) {
					return 'Sem permissão para conceder mais de ' + perfil.percentualMaximoDescontoPorItem + '% de desconto por item. Você pode conceder apenas R$' + valorPermitidoEmReal + ' de desconto por item'
				}
			}

			return true
		}
	}*/

	get retornoDeDemonstracao(){
		return this.venda.isVendaReaberta && (this.venda.isDemonstracao || this.venda.isConsignado)
	}

	get descontoEValido() {
		return () => {
			if (!this.item.desconto) return true
			
			if (!this.item.desconto.isPercentual && (
				(
					this.item.quantidade > 0 &&
						this.item.desconto.valor > ((new Decimal(this.item.preco).mul(this.item.quantidade).toNumber()) - 0.01)
				) || (
					this.item.quantidade < 0 &&
						this.item.desconto.valor > ((new Decimal(this.item.preco).mul(this.item.quantidade).toNumber()) + 0.01) * -1
				)
			)
			) {
				return 'Valor maior que o total da venda'
			}

			if (this.item.desconto.isPercentual && this.item.desconto.valor > 99.99) {
				return 'Valor não pode ser maior que 99.99%'
			}
				
			const perfil: null | Perfil = localStorage[PERFIL_LOGADO]
				? JSON.parse(localStorage[PERFIL_LOGADO])
				: null
			if(!perfil) return

			if(this.item.desconto.valor > 0 && perfil.percentualMaximoDesconto > 0){
				let valorTotalDeDescontoDosItens = 0;
				let valorBrutoDaVenda = 0;

				for(let i=0; this.venda.itens.length > i; i++){
					const item = this.venda.itens[i]

					if((this.retornoDeDemonstracao && item.isCompra) || !this.retornoDeDemonstracao){
						
						if(!UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto') && !this.usuarioAutenticado 
						&& item.desconto.valor !== 0 && !item.possuiDescontoPorTabela){
							this.validaPermissaoDeDesconto();
						}
						
						if(!item.possuiDescontoPorTabela && item.desconto.valor > 0){
							if(item.desconto.isPercentual){
								const retiraValorDaPorcentagem = item.preco * (item.desconto.valor / 100);
								valorTotalDeDescontoDosItens = valorTotalDeDescontoDosItens + retiraValorDaPorcentagem;
							}else{
								valorTotalDeDescontoDosItens = valorTotalDeDescontoDosItens + item.desconto.valor;
							}
						}
						if(item.possuiDescontoPorTabela){
							const descontoDaTabelaDePreco = (this.venda.itens[i].preco * (this.venda.itens[i].desconto.valor / 100))
							valorBrutoDaVenda = valorBrutoDaVenda + (this.venda.itens[i].preco - descontoDaTabelaDePreco)
						}else{
							valorBrutoDaVenda = valorBrutoDaVenda + (this.venda.itens[i].preco)
						}
					}
				}

				let porcentagemDeDescontoDaCapa = 0;

				if(this.venda.desconto){
					if(!this.venda.desconto.isPercentual){
						const somaDosItensComDescontoAplicado = (this.validarTotalDaVenda(this.venda) + this.venda.desconto.valor)
						porcentagemDeDescontoDaCapa = (this.venda.desconto.valor / somaDosItensComDescontoAplicado) *100;
					} else{
						porcentagemDeDescontoDaCapa = this.venda.desconto.valor
					}
				}

				const porcentagemDeDescontoDosItens = (valorTotalDeDescontoDosItens / valorBrutoDaVenda) *100

				let porcentagemDeDescontoTotalDaVenda = porcentagemDeDescontoDosItens + porcentagemDeDescontoDaCapa
				porcentagemDeDescontoTotalDaVenda = Math.floor(porcentagemDeDescontoTotalDaVenda * 100) / 100;

				if(porcentagemDeDescontoTotalDaVenda > perfil.percentualMaximoDesconto && this.venda.pagamentos.length < 1 && !this.usuarioAutenticado){
					AlertModule.setError('Somando todos os descontos dos itens, você possui ' + 
					porcentagemDeDescontoTotalDaVenda.toFixed(2) + '% Mas você tem permissão para conceder apenas ' + 
					perfil.percentualMaximoDesconto + '% de desconto na venda total.')
					return false
				}
			}
			
			return true
		}
	}

	async validaPermissaoDeDesconto(){
		if (!await VendaModule.autenticarAcao({ regra: 'pode-conceder-desconto' })){
			for(let i=0; this.venda.itens.length > i; i++){
				this.venda.itens[i].desconto.valor = 0;
			}
			
			return 
		}
		this.usuarioAutenticado = true
	}

	validarTotalDoItem(item: ItemDaVenda) {
		let totalDoItem = obterTotalDoItem(item)
		if (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') {
			return totalDoItem
		} else if (totalDoItem < 0) {
			return (totalDoItem = 0)
		}
		return totalDoItem
	}

	validarTotalDaVenda(venda: Venda) {
		let totalDaVenda = obterTotalDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') {
			return totalDaVenda
		} else if (totalDaVenda < 0) {
			return (totalDaVenda = 0)
		}
		return totalDaVenda
	}

	validarSubTotalDaVenda(venda: Venda) {
		let subTotalDaVenda = obterTotalDosItensDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') {
			return subTotalDaVenda
		} else if (subTotalDaVenda < 0) {
			return (subTotalDaVenda = 0)
		}
		return subTotalDaVenda
	}

	get loja() {
		return VendaModule.lojaDaVenda
	}

	get venda() {
		return VendaModule.vendaAtual as Venda
	}

	get descontoEmSerie() {
		return VendaModule.descontoEmSerie
	}

	set descontoEmSerie(desconto: Desconto | null) {
		VendaModule.setDescontoEmSerie(desconto)
	}

	async concluir() {
		if(!this.loja) return
		const tipoDeCliente =
			this.venda && this.venda.tipoDeCliente ? this.venda.tipoDeCliente : null

		const itens = this.normalizaCasoSejaComposto(this.item).map(itemMap => ({
			...itemMap,
			desconto: this.descontoEmSerie || itemMap.desconto,
		}))
		

		try {
			await AplicarTabelaDePrecoUseCase({
				loja: this.loja,
				itens,
				tipoDeCliente,
				validarLimiteDeProduto: true,
				vendaAtual: this.venda,
			})

			this.mostrarDialog = false

			this.permissaoConcedida = await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })
			
		} catch(error) {
			AlertModule.setError(error)
			if (AlertModule.getErrorMsg === 'Cliente já esgotou limite de compras desse produto no dia atual'){
				this.mostrarDialog = false
				if (!(await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })) || !this.venda) return 
				this.item.quantidade = this.quantidade
			}
		} finally {
			if(this.permissaoConcedida) this.item.quantidade = this.quantidade
		}

	}

	normalizaCasoSejaComposto(item: ItemDaVenda) {
		let itens: ItemDaVenda[] = []
		
		if (item.produto.tipo === 'Composto') {
			const idConjunto = uuidv4()

			itens = item.produto.itens.map(itemDoProduto => ({
				...item,
				produto: itemDoProduto.produto,
				quantidade: item.quantidade * itemDoProduto.quantidade,
				idConjunto: idConjunto,
				desconto: itemDoProduto.desconto,
			}))
		} else {
			itens = [item]
		}

		return itens
	}
  
	get possuiAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}

	setPercentual(valor: boolean) {
		if (!this.item.desconto) return
		this.item.desconto.isPercentual = valor
	}

	get lojaDaVendaAtual(): string | undefined {
		return VendaModule.turnoDeVenda
			? (VendaModule.vendaAtual?.pontoDeVenda?.loja.id || undefined)
			: undefined
	}

	permissoesDoPerfil(permissao){
		const perfil: null | Perfil = localStorage[PERFIL_LOGADO]
			? JSON.parse(localStorage[PERFIL_LOGADO])
			: null
			
		if (!perfil) {
			AlertModule.setError('Perfil não encontrado')
			return
		}
			
		return perfil.permissoes.includes(permissao)
	}

	get habilitaCampoDeDesconto(){
		return this.item.possuiDescontoPorTabela || 
			(this.ehUmaDevolucao && this.item.quantidade < 0) || 
			this.item.quantidade < 1 || 
			(!this.item.isCompra && this.venda.isDemonstracao) || 
			this.item.produto.isValePresente
	}

	aplicarDesconto(item: ItemDaVenda){
		if (item.quantidade > 0) {
			item.desconto.valor = this.item.desconto.valor;
		} else {
			item.desconto.valor = 0;
		}
	}
}

